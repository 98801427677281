import { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { GetRequest, PostRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { setUser, UserInfo } from "../../slice/UserSlice";
import { AppDispatch } from "../../store/store";
import { error } from "console";
import { useNavigate } from "react-router-dom";

const DeliveryAddress = (props: any) => {
  let { showAddressForm, setShowAddAddress } = props;
  const userDetail = useSelector(UserInfo);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availablePostalCode, setAvailablePostalCode] = useState<any>();
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "India",
    landmark: "",
  });
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();
  // Create refs for each input field
  const nameRef = useRef<any>(null);
  const mobileNumberRef = useRef<any>(null);
  const line1Ref = useRef<any>(null);
  const line2Ref = useRef<any>(null);
  const streetRef = useRef<any>(null);
  const cityRef = useRef<any>(null);
  const stateRef = useRef<any>(null);
  const postalCodeRef = useRef<any>(null);
  const countryRef = useRef<any>(null);
  const landmarkRef = useRef<any>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const handleCloseTheAddress = () => {
    setShowAddAddress(() => false);
  };

  useEffect(() => {
    getAllPostalCode();
  }, []);

  const getAllPostalCode = async () => {
    let response: any = await GetRequest("/getAll-postalCode");
    if (response.status === 200) {
      setAvailablePostalCode(() => response.data);
    } else {
      showErrorToast("failed to fetch postalCodes");
    }
  };

  const handleChange = async (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]:
        name === "postalCode"
          ? value.length <= 6
            ? value
            : prevData[name]
          : value,
    }));

    setErrors((prevData: any) => ({
      ...prevData,
      [name]:
        name === "postalCode" &&
        prevData[name] !== "We don't deliver products in your area for now."
          ? prevData[name]
          : "",
    }));

    if (name === "postalCode" && value.length === 6) {
      let checkPostalCode = await availablePostalCode.filter(
        (postalCode: any) => {
          return postalCode.postalCode == value; // Use return statement inside the callback function
        }
      );

      if (checkPostalCode.length !== 0) {
        setErrors((prevData: any) => ({
          ...prevData,
          [name]: "",
        }));
      } else {
        setErrors((prevData: any) => ({
          ...prevData,
          [name]: `We are only available for these pincodes: ${availablePostalCode
            .flatMap((postalCode: any) => postalCode.postalCode)
            .join(" ")}`,
        }));
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault(); // Prevent default form submission behavior
    const newErrors: any = {};

    // Validate fields and populate errors
    if (!formData.name) {
      newErrors.name = "Name is required";
      // showErrorToast("Name is required");
    }

    if (
      !formData.mobileNumber ||
      formData.mobileNumber.length !== 10 // Check for exactly 10 digits
    ) {
      newErrors.mobileNumber = "Mobile number is required and should be valid";
      // showErrorToast("Mobile number is required and should be valid");
    }
    if (!formData.address1) {
      newErrors.address1 = "Address Line 1 is required";
      // showErrorToast("Address Line 1 is required");
    }
    if (!formData.street) {
      newErrors.street = "Street is required";
      // showErrorToast("Street is required");
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      // showErrorToast("City is required");
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      // showErrorToast("State is required");
    }
    if (!formData.postalCode) {
      newErrors.postalCode = "Postal code is required";
      // showErrorToast("Postal code is required");
    }
    if (
      errors?.postalCode?.includes(
        "We are only available for these postal codes"
      )
    )
      newErrors.postalCode = errors.postalCode;
    setErrors(newErrors);

    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      return; // Exit if there are validation errors
    }

    setIsLoading(true); // Set loading state to true before making the request

    PostRequest("/create-address", formData)
      .then((res: any) => {
        if (res.status === 201) {
          setFormData({
            name: "",
            mobileNumber: "",
            address1: "",
            address2: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "India",
            landmark: "",
          });

          dispatch(
            setUser({
              name: userDetail.name || "",
              number: userDetail.number || "",
              gender: userDetail.gender || "",
              email: userDetail.email || "",
              addresses: res.data.addresses,
              userIsLoggedIn: true,
            })
          );

          setErrors({}); // Clear errors
          setShowAddAddress(() => false); // Hide the add address form
          showSuccessToast("Added successfully");

          if (urlParams.get("redirect") === "cart") {
            // Redirect to the desired URL
            navigate("/cart");
          }
        }
      })
      .catch((error) => {
        console.error("Error adding address:", error);
        showErrorToast("Failed to add address");
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after request completes
      });
  };

  return (
    <div className="fixed w-[100vw] mt-[3rem] no-scrollbar h-[100vh] z-[99] top-0 flex flex-col left-0 justify-center overflow-x-scroll bg-[#868786] bg-opacity-[0.5] backdrop-blur-xs">
      <div className="w-full h-[70vh] relative overflow-x-scroll flex justify-center my-0 mx-auto rounded-lg">
        <div className="p-6 border w-[40%] relative overflow-x-scroll sm:w-[97%] border-gray-300 no-scrollbar bg-white rounded-lg">
          <div className="flex justify-between items-start">
            <h1 className="font-bold text-[19px] mb-[1rem] ">
              Delivery Address
            </h1>
            <span
              className="text-red-600 cursor-pointer"
              onClick={handleCloseTheAddress}
            >
              <RxCross1 />
            </span>
          </div>
          <div>
            <label className="block mb-6">
              <span className="text-gray-700">Your name</span>
              <input
                ref={nameRef}
                name="name"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="Your full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Mobile Number</span>
              <input
                ref={mobileNumberRef}
                name="mobileNumber"
                type="tel"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="898989XXXX"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
              {errors.mobileNumber && (
                <p className="text-red-500 text-sm">{errors.mobileNumber}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Line 1</span>
              <input
                ref={line1Ref}
                name="address1"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.address1}
                onChange={handleChange}
                required
              />
              {errors.address1 && (
                <p className="text-red-500 text-sm">{errors.address1}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Line 2</span>
              <input
                ref={line2Ref}
                name="address2"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.address2}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Street</span>
              <input
                ref={streetRef}
                name="street"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.street}
                onChange={handleChange}
                required
              />
              {errors.street && (
                <p className="text-red-500 text-sm">{errors.street}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">City</span>
              <input
                ref={cityRef}
                name="city"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.city}
                onChange={handleChange}
                required
              />
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">State</span>
              <input
                ref={stateRef}
                name="state"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.state}
                onChange={handleChange}
                required
              />
              {errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Postal Code</span>
              <input
                ref={postalCodeRef}
                name="postalCode"
                type="tel"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="eg: 111000"
                value={formData.postalCode}
                onChange={handleChange}
                minLength={6}
                min={6}
                required
              />
              {errors.postalCode && (
                <p className="text-red-500 text-sm">{errors.postalCode}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Country</span>
              <input
                ref={countryRef}
                name="country"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                value={formData.country}
                readOnly={true}
              />
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Landmark</span>
              <input
                ref={landmarkRef}
                name="landmark"
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.landmark}
                onChange={handleChange}
              />
            </label>
            <div className="text-right  pb-[1rem] w-[100%]">
              <button
                onClick={isLoading ? () => {} : handleSubmit}
                className="h-10 px-5 relative text-indigo-100 bg-indigo-700 rounded-lg transition-colors duration-150 focus:shadow-outline hover:bg-indigo-800"
              >
                {isLoading ? (
                  <div className="absolute top-[-17px] left-[0%]">
                    <Loader />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryAddress;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  page,
  totalItemForProduct,
  totalPageForProduct,
} from "../slice/ProductSlice";

const Pagination = () => {
  const totalPages = useSelector(totalPageForProduct);
  const totalItems = useSelector(totalItemForProduct);
  const pageNumber = useSelector(page);
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const [mainUrl, setMainUrl] = useState("");

  useEffect(() => {
    if (query.get("sub-category-id")) {
      setMainUrl(
        () =>
          `/product-list?sub-category-id=${query.get(
            "sub-category-id"
          )}&page=${pageNumber}`
      );
    }
    if (query.get("category_id") && query.get("category")) {
      setMainUrl(
        () =>
          `/product-list?category_id=${query.get(
            "category_id"
          )}&categpry=${query.get("category")}&page=${pageNumber}`
      );
    }
  }, [
    query.get("sub-category-id"),
    query.get("category_id"),
    query.get("page"),
  ]);

  const giveUrlAccToPage = (index: number) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    searchParams.set("page", index.toString());

    const newUrl = `${currentUrl.origin}${
      currentUrl.pathname
    }?${searchParams.toString()}`;

    return newUrl;
  };

  return (
    <div className="flex m-auto justify-center ">
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px text-sm">
          {/* <li>
            <Link
             
              to=""
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg "
            >
              Previous
            </Link>
          </li> */}
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1}>
              <Link
                to={giveUrlAccToPage(index + 1)}
                // onClick={handleClickOnPageNumber}
                className={
                  pageNumber == index + 1
                    ? "flex items-center justify-center text-white px-3 h-8 leading-tight text-gray-500  border border-gray-300 bg-primary"
                    : "flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100"
                }
                style={{
                  borderRadius:
                    index === 0
                      ? "0.375rem 0 0 0.375rem"
                      : index === totalPages - 1
                      ? "0 0.375rem 0.375rem 0"
                      : "0",
                }}
              >
                {index + 1}
              </Link>
            </li>
          ))}

          {/* <li>
            <Link
              to="#"
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg "
            >
              Next
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;

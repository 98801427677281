import React, { useCallback, useState } from "react";
import { FaLocationArrow, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../../slice/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../../../slice/UserSlice";
import Loader from "../../../common/loader/Loader";
import { BsCart3 } from "react-icons/bs";
import {
  decrementQuantity,
  incrementQuantity,
} from "../../../slice/ProductSlice";

interface ProductInfoProps {
  product: any;
  setProduct: any;
  variations: any;
  productsWithSameVariant: any;
  handleSelectVariant: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  setProduct,
  variations,
  productsWithSameVariant,
  handleSelectVariant,
}) => {
  const [quantity, setQuantity] = useState<number>(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userIsLoggedIn = useSelector(IsLoggedIn);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };

  const handleIncrementForQuantity = () => {
    let newProduct = { ...product };
    newProduct.quantityByUser =
      newProduct.quantityByUser !== newProduct.totalStock
        ? newProduct.quantityByUser + 1
        : newProduct.quantityByUser;
    setProduct((prevProduct: any) => {
      return { ...prevProduct, product: newProduct };
    });
  };

  const handleDecrementForQuantity = () => {
    let newProduct = { ...product };
    newProduct.quantityByUser =
      newProduct.quantityByUser > 1
        ? newProduct.quantityByUser - 1
        : newProduct.quantityByUser;
    setProduct((prevProduct: any) => {
      return { ...prevProduct, product: newProduct };
    });
  };

  const handleAddToCart = useCallback(async (product: any) => {
    await dispatch(
      addProduct({
        ...product,
        total: Number(product.salePrice) * product.quantityByUser,
      })
    );

    setProduct((prevProduct: any) => {
      return {
        ...prevProduct,
        product: {
          ...prevProduct.product,
          addToCartStatus: "Update Cart",
        },
      };
    });
  }, []);

  return (
    <div className="p-6 sm:p-3 bg-white shadow-lg rounded-lg">
      {/* <h2 className="text-2xl text-center font-bold text-gray-800 capitalize mb-4 bg-[#0047AB] text-white p-2">Air Conditioner</h2> */}
      <div className="flex items-center justify-between">
        {/* <span className="bg-red-500 text-white px-1 rounded h-4 text-xs">
          27% off
        </span> */}
        <Link
          to="/contact"
          className="block text-blue-500 mb-2 text-center flex items-center hover:underline text-xs"
        >
          <FaLocationArrow className="mr-2" />
          Visit Cayro Shop store
        </Link>
      </div>
      <h5 className="text-xl font-semibold mb-2 sm:text-sm">
        {product?.productName}
      </h5>
      <div className="mb-4">
        <div className="text-gray-500 mb-2 sm:text-xs">
          in{" "}
          <p className="text-blue-500 inline">
            {product?.categoryId?.category}
          </p>
        </div>
        {/* <div className="flex items-center mb-2">
          <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className="w-6 h-6 sm:h-3 sm:w-3 text-yellow-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 22 12 18.27 5.82 22 7 14.14 2 9.27l6.91-1.01L12 2z" />
              </svg>
            ))}
          </div>
          <Link to="#reviews" className="text-blue-500 ml-2 sm:text-sm">
            5.00 (25 Reviews)
          </Link>
        </div> */}
      </div>
      <div className="mb-4 flex items-center space-x-4">
        <p className="text-2xl font-bold sm:text-sm">₹{product?.salePrice}</p>
        <p className="text-md font-bold sm:text-sm">M.R.P</p>
        <p className="text-md line-through text-red-500 sm:text-sm">
          ₹{product?.regularPrice}
        </p>
        <p className="text-green-500 font-semibold text-sm sm:text-xs">
          Available in stock
        </p>
      </div>
      <div className="flex flex-col items-start">
        <div className="flex items-center space-x-2 mb-4">
          <button
            type="button"
            onClick={() => handleDecrementForQuantity()}
            className="p-2 px-6 text-[17px] flex justify-center items-center sm:px-2 bg-gray-200 rounded sm:h-[28px] text-bold"
          >
            -
          </button>
          <input
            type="number"
            className="w-16 sm:w-8 p-2 text-[17px] border sm:h-[28px] rounded text-center"
            value={product?.quantityByUser}
            onChange={handleQuantityChange}
          />
          <button
            type="button"
            onClick={() => handleIncrementForQuantity()}
            className="p-2 sm:p-3 flex justify-center items-center px-6 bg-gray-200 sm:h-[28px] rounded text-bold"
          >
            +
          </button>
        </div>
        {product.quantityByUser == product.totalStock ? (
          <p
            className="text-xs text-center text-purple-600 mb-[0.6rem]"
            id="hs-validation-name-error-helper"
          >
            Only {product.totalStock} left in stock
          </p>
        ) : (
          ""
        )}
        <div className="flex space-x-4">
          <button
            onClick={() => {
              handleAddToCart(product);
            }}
            className="border sm:min-w-[120px]  sm:text-xs border-blue-500  text-blue-500 sm:px-2 sm:py-1 px-4 py-2 rounded flex-1 min-w-[184px] hover:bg-white hover:text-blue-500"
          >
            {product?.addToCartStatus}
            <BsCart3 className="inline mb-[0.2rem] ml-[0.2rem]" />
            {product?.isLoading && (
              <div className="absolute top-[-18px] left-[14px]">
                <Loader />
              </div>
            )}
          </button>

          <button
            type="submit"
            onClick={() => {
              handleAddToCart(product);
              navigate("/cart");
            }}
            className="border sm:min-w-[120px] rounded-lg transition-transform duration-300 transform sm:text-xs  text-white bg-blue-500 px-4 py-2 rounded flex-1 min-w-[184px]  hover:scale-105"
          >
            Buy Now
          </button>
        </div>
      </div>

      <div>
        {variations.length > 0 &&
          variations.map((variation: any) => {
            return (
              <div>
                <p className="text-[#555] font-[400] mt-[0.5rem]">
                  {variation.name} :
                </p>
                <ul className="flex items-center">
                  {variation?.value?.length > 0 &&
                    variation?.value?.map((value: string) => {
                      return (
                        <li
                          onClick={() => handleSelectVariant(variation, value)}
                          className="mx-[1rem] my-[0.4rem]"
                        >
                          <p
                            className={
                              product?.variation?.filter(
                                (varItem: any) =>
                                  varItem.name === variation.name
                              ).length > 0 &&
                              product?.variation?.find(
                                (varItem: any) =>
                                  varItem.name === variation.name
                              )?.value === value
                                ? "bg-[#3B81F6] text-white cursor-pointer rounded-xl p-[0.4rem] text-[14px]"
                                : "border-[1.5px] border-solid border-[#ddd] text-black cursor-pointer rounded-xl p-[0.4rem] text-[14px]"
                            }
                          >
                            {value}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
      </div>
      <div className="flex space-x-4 mt-4">
        {/* <p className="ml-4 gap-3 text-blue-500 sm:text-xs flex items-center flex-1 min-w-[184px] sm:min-w-[100px] hover:text-blue-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-down-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
            />
          </svg>
          Compare
        </p> */}
        {/* <p className="text-blue-500 gap-3 sm:text-xs flex items-center flex-1 min-w-[184px] sm:min-w-[100px] hover:text-blue-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-heart"
            viewBox="0 0 16 16"
          >
            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
          </svg>
          Add to wishlist
        </p> */}
      </div>
      <div className="mt-4">
        <ul className="space-y-2">
          <li className="flex items-center sm:text-xs">
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
            </svg>
            Free shipping on orders over ₹2000
          </li>
          <li className="flex items-center sm:text-xs">
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
            </svg>
            Save extra with No Cost EMI
          </li>
          <li className="flex items-center sm:text-xs">
            <svg
              className="w-7 h-7 mr-2"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M21 13.997h-6.76c-.353-1.12-1.419-2-2.74-2s-2.387.88-2.74 2h-6.76c-.413 0-.5.207-.5.62v4.756c0 .407.293.621.5.621h.5v1.621c0 .413.207.5.62.5h2.76c.413 0 .621-.207.621-.5v-1.621h10.502v1.621c0 .413.207.5.62.5h2.76c.413 0 .621-.207.621-.5v-1.621h.5c.207 0 .5-.207.5-.621v-4.756c0-.413-.207-.62-.5-.62zm-16-6c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zm8 0c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zm8 0c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zM4.5 14.997h2v1.005h-2v-1.005zm0 2.007h2v1.005h-2v-1.005zm14.998 0v1.005h-10.502v-1.005h10.502z" />
            </svg>
            100% money back guarantee
          </li>
          <li className="flex items-center">
            <div className="flex items-center">
              <p className="mr-2 text-[#5675f6] text-bold">Share: </p>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pinterest"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-800 border border-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-telegram"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                </svg>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProductInfo;

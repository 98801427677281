import React from "react";

interface InputTextProps {
  name: string;
  placeholder: string;
  onHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type: string;
  disabled:boolean;
}

const InputText: React.FC<InputTextProps> = ({
  name,
  placeholder,
  onHandleChange = () => {},
  value,
  type,
  disabled,
}) => {
  return (
    <>
      <input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        required
        value={value}
        onChange={(event) => onHandleChange(event)}
        className="w-full mt-2 p-2 outline-none border-[0.5px] border-[#ddd] rounded"
        disabled={disabled}
      />
    </>
  );
};

export default InputText;
import axios from "axios";

const BASE_URL = "https://cayroshop.in/v1/";
// const BASE_URL = "http://192.168.1.110:3000/v1/";
// const BASE_URL = "https://live-cayro-buyer-backend-1.onrender.com/v1/";

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetRequest } from "../../api/Request";
import { useQuery } from "@tanstack/react-query";

type Blog = {
  id: number;
  title: string;
  // Add other blog properties here
};

const Blog = () => {
  // Assuming GetRequest is defined elsewhere
  // const {
  //   data: allBlog,
  //   error,
  //   isLoading,
  // } = useQuery<any, Error>({
  //   queryKey: ["getAllBlog"],
  //   queryFn: async () => {
  //     const response = await GetRequest("getAllBlog");
  //     return response.data.data;
  //   },
  //   initialData: [],
  // });

  // console.log(isLoading, "%%% this is loading");
  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      <div className="container h-full w-full mx-auto p-4 md:px-3 flex flex-col gap-8 mt-[8rem]">
        <div className="flex md:flex-col gap-4 w-[100%] flex-row h-[600px]">
          <article
            className="relative bg-cover bg-center md:h-70 h-full w-2/3 md:w-[100%] "
            style={{
              backgroundImage:
                "url(https://i0.wp.com/motta.uix.store/electronic/wp-content/uploads/sites/6/2012/03/b9.jpg?fit=1400%2C788&ssl=1)",
            }}
          >
            <Link to="/" className="block h-full">
              <span className="motta-svg-icon motta-svg-icon--video post-format-icon icon-video absolute inset-0 flex items-center justify-center">
                <svg
                  width="24"
                  height="24"
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  viewBox="0 0 32 32"
                  className="w-6 h-6 text-white"
                >
                  <path d="M13.067 12.24l6.267 3.76-6.267 3.76v-7.52zM10.667 8v16l13.333-8-13.333-8z"></path>
                </svg>
              </span>
            </Link>
            <div className="absolute inset-0 p-4 flex flex-col justify-end bg-gradient-to-t from-black via-transparent to-transparent">
              <div className="mb-2">
                <Link to="/" className="text-gray-300 text-m sm:text-sm">
                  TV & Audio
                </Link>
              </div>
              <h2 className="text-m sm:text-sm font-bold text-white">
                <Link to="/" className="hover:underline">
                  TV Buying Guide: A Need-to-Know to Know What You Need
                </Link>
              </h2>
              <div className="flex items-center mt-4 text-m sm:text-sm">
                <Link
                  className="motta-button motta-button--small motta-button--ghost px-2 py-2 border border-gray-300 text-gray-300 hover:bg-gray-100"
                  to="/"
                >
                  Read More
                </Link>
                <p className="text-gray-300 ml-4 text-m sm:text-sm">
                  Aug 15,2024
                </p>
              </div>
            </div>
          </article>

          {/* Smaller Featured Articles */}
          <div className="flex flex-col gap-4 flex-1">
            <article
              className="relative bg-cover bg-center md:h-32 h-1/2"
              style={{
                backgroundImage:
                  "url(https://i0.wp.com/motta.uix.store/electronic/wp-content/uploads/sites/6/2012/03/b13-1.jpg?w=1400&ssl=1)",
              }}
            >
              <Link to="/" className="block h-full">
                <div className="absolute inset-0 p-4 flex flex-col justify-end bg-gradient-to-t from-black via-transparent to-transparent">
                  <div className="mb-2">
                    <Link to="/" className="text-gray-300 text-m sm:text-sm">
                      Electronics
                    </Link>
                  </div>
                  <h2 className="text-md font-bold text-white">
                    <Link
                      to="/"
                      className="hover:underline test-m sm:text-sm mb-4"
                    >
                      The best sports headphones for working out
                    </Link>
                  </h2>
                  <p className="text-gray-300 mt-2 text-m sm:text-sm">
                    Aug 15,2024
                  </p>
                </div>
              </Link>
            </article>

            <article
              className="relative bg-cover bg-center md:h-32 h-1/2"
              style={{
                backgroundImage:
                  "url(https://i0.wp.com/motta.uix.store/electronic/wp-content/uploads/sites/6/2018/11/b1-1.jpg?w=1400&ssl=1)",
              }}
            >
              <Link to="/" className="block h-full">
                <div className="absolute inset-0 p-4 flex flex-col justify-end bg-gradient-to-t from-black via-transparent to-transparent">
                  <div className="mb-2 text-m sm:text-sm">
                    <Link to="/" className="text-gray-300">
                      Lifestyle
                    </Link>
                  </div>
                  <h2 className="text-md font-bold text-white">
                    <Link to="/" className="hover:underline text-m sm:text-sm">
                      5 Easy Holiday Home Decor Swaps
                    </Link>
                  </h2>
                  <p className="text-gray-300 mt-2 text-m sm:text-sm">
                    Aug 15,2024
                  </p>
                </div>
              </Link>
            </article>
          </div>
        </div>

        <div className="flex flex-col md:gap-4 sm:gap-6 gap-8">
          <h1 className="text-xl sm:text-xl md:text-xl font-bold text-gray-900">
            Explore Articles
          </h1>

          <article className="flex sm:flex-col flex-row items-start gap-4 sm:gap-6">
            <Link
              className="md:w-full sm:w-1/3 md:h-full"
              to="/"
              aria-hidden="true"
            >
              <img
                width="364"
                height="205"
                src="https://i0.wp.com/motta.uix.store/electronic/wp-content/uploads/sites/6/2023/11/iphone_14_pro_na_cor_roxa_imagem_emerson_alecrimtecnoblog-1060x596-1.webp?resize=364%2C205&ssl=1"
                className="rounded-md md:w-full md:h-full object-cover"
                alt="iPhone 14 Pro"
              />
            </Link>
            <div className="flex-1 h-full flex flex-col justify-between">
              <div>
                <div className="mb-2">
                  <Link
                    to="/"
                    rel="category tag"
                    className="text-sm sm:text-base md:text-lg text-blue-500 hover:underline"
                  >
                    Electronics
                  </Link>
                </div>
                <h2 className="text-base sm:text-lg md:text-xl font-semibold">
                  <Link
                    to="/"
                    rel="bookmark"
                    className="text-gray-900 hover:underline"
                  >
                    iPhone 14 Pro tem o menor preço desde maio na Black Friday
                  </Link>
                </h2>
                <p className="mt-2 sm:mt-4 text-sm sm:text-base md:text-lg text-gray-600">
                  Celular na versão de 1 TB recebeu desconto e opção de
                  parcelamento em até 10x sem juros.
                </p>
              </div>
              <div className="mt-2 sm:mt-4 text-xs sm:text-sm md:text-base text-gray-500">
                August 08, 2024
              </div>
            </div>
          </article>

          {/* <article className="flex sm:flex-col flex-row items-start gap-4 sm:gap-6">
            <Link
              className="md:w-full sm:w-1/3 md:h-full"
              to="/"
              aria-hidden="true"
            >
              <img 
                
                width="364"
                height="205"
                src="https://i0.wp.com/motta.uix.store/electronic/wp-content/uploads/sites/6/2018/11/b33-1.jpg?resize=364%2C205&ssl=1"
                className="rounded-md md:w-full md:h-full object-cover"
                alt="iPhone 14 Pro"
              />
            </Link>
            <div className="flex-1 h-full flex flex-col justify-between">
              <div>
                <div className="mb-2">
                  <Link
                    to="/"
                    rel="category tag"
                    className="text-sm sm:text-base md:text-lg text-blue-500 hover:underline"
                  >
                    Electronics
                  </Link>
                </div>
                <h2 className="text-base sm:text-lg md:text-xl font-semibold">
                  <Link
                    to="/"
                    rel="bookmark"
                    className="text-gray-900 hover:underline"
                  >
                    iPhone 14 Pro tem Black Friday
                  </Link>
                </h2>
                <p className="mt-2 sm:mt-4 text-sm sm:text-base md:text-lg text-gray-600">
                  Celular na versão de 1 TB recebeu parcelamento em até 10x sem
                  juros.
                </p>
              </div>
              <div className="mt-2 sm:mt-4 text-xs sm:text-sm md:text-base text-gray-500">
                August 08, 2024
              </div>
            </div>
          </article> */}
        </div>
      </div>
    </>
  );
};

export default Blog;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import Loader from "../../common/loader/Loader";

const ForgetPassword = () => {
  const [step, setStep] = useState(1); // Step 1: Request OTP, Step 2: Verify OTP and set new password
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingForRequestingOtp, setIsLoadingForRequestingOtp] =
    useState<boolean>(false);
  const [isLoadingForSubmitingOtp, setIsLoadingForSubmitingOtp] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const handleRequestOtp = async (e: any) => {
    e.preventDefault();

    if (!mobileNumber || mobileNumber.length > 10 || mobileNumber.length < 10) {
      return showErrorToast("Mobile number is required and valid");
    }
    setIsLoadingForRequestingOtp(() => true);
    try {
      // Replace with your API endpoint to send OTP
      const response = await PostRequest("/send-forgot-otp", {
        number: mobileNumber,
      });

      if (response.status === 200) {
        showSuccessToast("OTP sent successfully! Please check your mobile.");
        setStep(2);
      }
    } catch (error: any) {
      showErrorToast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message
      );
    }
    setIsLoadingForRequestingOtp(() => false);
  };

  const handleVerifyOtp = async (e: any) => {
    setIsLoadingForSubmitingOtp(() => true);
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!otp || !newPassword) {
      setError("Both OTP and new password are required.");
      return;
    }

    try {
      const response = await PostRequest("/forgotPassword", {
        number: mobileNumber,
        password: newPassword,
        otp: otp,
      });
      if (response.status === 200) {
        showSuccessToast("Password reset successfully! You can now log in.");
        navigate("/login");
      }

      // Optionally, navigate to login page or reset state
    } catch (error: any) {
      showErrorToast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message
      );
    }
    setIsLoadingForSubmitingOtp(() => false);
  };

  return (
    <div className="flex items-center justify-center h-[80vh] mt-[5rem]">
      <main
        id="content"
        role="main"
        className="w-full max-w-md mx-auto p-6 sm:p-2"
      >
        <div className="mt-7 bg-white rounded-xl shadow-lg border-[#ddd] border-[0.8px]">
          <div className="p-4 sm:p-2">
            <div className="text-center">
              <h1 className="block text-2xl sm:text-[17px] font-semibold text-gray-800">
                Forgot password?
              </h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Remember your password?
                <Link
                  className="text-blue-600 ml-[0.2rem] decoration-2 hover:underline font-medium"
                  to="/login"
                >
                  Login here
                </Link>
              </p>
            </div>

            <div className="mt-5">
              {step === 1 ? (
                <form onSubmit={handleRequestOtp}>
                  <div className="grid gap-y-4">
                    <div>
                      <label
                        htmlFor="mobileNumber"
                        className="block text-sm font-semibold ml-1 mb-2"
                      >
                        Mobile Number
                      </label>
                      <div className="relative">
                        <input
                          type="number"
                          id="mobileNumber"
                          name="mobileNumber"
                          value={mobileNumber}
                          onChange={(e) =>
                            e.target.value.length <= 10
                              ? setMobileNumber(e.target.value)
                              : e.target.value
                          }
                          className="py-2 px-4 block w-full border-[1.5px] border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="py-3 px-4 relative sm:py-[0.55rem] sm:text-[0.8rem] inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    >
                      Send OTP
                      {isLoadingForRequestingOtp ? (
                        <div className="absolute top-[-15px] right-[39%]">
                          <Loader />
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                    {error && (
                      <p className="text-xs text-red-600 mt-2">{error}</p>
                    )}
                    {successMessage && (
                      <p className="text-xs text-green-600 mt-2">
                        {successMessage}
                      </p>
                    )}
                  </div>
                </form>
              ) : (
                <div>
                  <div className="grid gap-y-4">
                    <div>
                      <label
                        htmlFor="otp"
                        className="block text-sm font-semibold ml-1 mb-2"
                      >
                        OTP
                      </label>
                      <div className="relative">
                        <input
                          type="number"
                          id="otp"
                          name="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          className="py-2 px-4 block w-full border-[1.5px] border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="newPassword"
                        className="block text-sm font-semibold ml-1 mb-2"
                      >
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="py-2 px-4 block w-full border-[1.5px] border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={handleVerifyOtp}
                      className="py-3 px-4 relative sm:py-[0.55rem] sm:text-[0.8rem] inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    >
                      Reset Password
                      {isLoadingForSubmitingOtp ? (
                        <div className="absolute top-[-18px] right-[39%]">
                          <Loader />
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                    {error && (
                      <p className="text-xs text-red-600 mt-2">{error}</p>
                    )}
                    {successMessage && (
                      <p className="text-xs text-green-600 mt-2">
                        {successMessage}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgetPassword;

import Lottie from "lottie-react";
import React, { useState, useEffect } from "react";
import carticon from "../../loti/carticon.json";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  updateQuantity,
  removeProduct,
  clearCart,
  toggleShippingCalculator,
  allProduct,
  calculateTotal,
} from "../../slice/CartSlice";
import emptyCart from "../../loti/emptyCart.json";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { PostRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";

const MobileCart: React.FC = () => {
  const { products, subtotal, discount, shippingCharges, total } = useSelector(
    (state: RootState) => state.cart
  );
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector(allProduct);
  const [isLoadingForCheckingOut, setIsLoadingForCheckingOut] =
    useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [paymentMethod, setPaymentMethod] = useState<string>("CC Avenue");
  const navigate = useNavigate();
  const [showShippingCalculator, setShowShippingCalculator] = useState(false);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const { addresses } = useSelector(UserInfo);
  const [shippingAddress, setShippingAddress] = useState<any>();

  const handleIncrementForQuantity = (id: string) => {
    dispatch(updateQuantity({ id, amount: "+1" }));
  };

  const handleDecrementForQuantity = (id: string) => {
    dispatch(updateQuantity({ id, amount: "-1" }));
  };

  const createOrder = async () => {
    try {
      setIsLoadingForCheckingOut(true);
      let allProductDetail = allProducts.flatMap((product: any) => {
        return { _id: product._id, quantity: product.quantityByUser };
      });
      let response = await PostRequest("createOrder", {
        payment_mode: paymentMethod,
        discount: 0,
        shippingCharge: 0,
        totalAmount: subtotal,
        productDetails: allProductDetail,
        shippingAddress,
      });

      if (response.status === 200) {
        window.location.href = await response?.data?.payment_links?.web;
      }
    } catch (error) {
      showErrorToast("Order Failed");
    } finally {
      setIsLoadingForCheckingOut(() => false);
    }
  };

  const handleClickToCheckout = async () => {
    if (userIsLoggedIn == false) {
      navigate(`/register?redirect=cart`);
    } else if (!userDetail.email) {
      navigate(`/profile?redirect=cart`);
      return showErrorToast("Please add your email");
    }
    if (userIsLoggedIn && addresses.length === 0) {
      navigate(`/list-of-address?redirect=cart`);
      return showErrorToast("Add your delivery address");
    }
    if (userIsLoggedIn && paymentMethod) await createOrder();
    if (userIsLoggedIn && !paymentMethod) showErrorToast("Select payment mode");
  };

  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(() => e.target.value);
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, []);

  return (
    <div
      className={
        allProducts?.length > 0
          ? "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 "
          : "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 flex justify-center items-center flex-col"
      }
    >
      <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-1.8rem] sm:mt-[-3rem] w-[100%] flex items-center">
        <Lottie
          animationData={carticon}
          style={{
            width: "80px",
            height: "100px",
            marginRight: "-1.4rem",
          }}
        />
        Cart
      </h1>
      <div className="widget_shopping_cart_content mt-[-2rem]">
        {allProducts.length > 0 ? (
          allProducts.map((product: any) => (
            <div
              key={product._id}
              className="woocommerce-mini-cart-item mini-cart-item flex items-center space-x-4 py-4 border-b border-gray-200"
            >
              <div className="woocommerce-mini-cart-item__thumbnail">
                <Link to="#">
                  <img
                    src={product?.images[0]?.url}
                    alt={product.productName}
                    className="w-28 h-28 md:w-24 md:h-24 object-contain rounded-lg shadow-sm object-contain"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  {/* <div className="woocommerce-mini-cart-item__name text-xs font-small text-gray-900"> */}
                  <div className="woocommerce-mini-cart-item__name text-sm font-medium text-gray-900 leading-snug">
                    <Link to="/" className="line-clamp-3">
                      {product.productName}
                    </Link>
                  </div>
                </div>
                <div className="text-xs text-gray-500">
                  <p>
                    <a className="wcfm_dashboard_item_title" target="_blank">
                      ₹{product.salePrice}
                    </a>
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <div className="quantity flex items-center space-x-1">
                    <button
                      // className="text-gray-500 text-xs border-2 border-gray-400 p-1 "
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                      onClick={() => handleDecrementForQuantity(product._id)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      // className="w-4 text-center border-gray-300 rounded-md text-xs"
                      className="w-10 text-center border-gray-300 rounded-md text-sm"
                      value={product.quantityByUser}
                      readOnly
                    />
                    <button
                      // className="text-gray-500 text-xs border-2 border-gray-400 p-1"
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                      onClick={() => handleIncrementForQuantity(product._id)}
                    >
                      +
                    </button>
                  </div>
                  <div className="ml-4 ">
                    <button
                      className="remove remove_from_cart_button text-red-600 hover:text-red-900 p-1"
                      aria-label="Remove this item"
                      data-product_id={String(product.id)}
                      data-cart_item_key={String(product.id)}
                      onClick={() => dispatch(removeProduct(product._id))}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path d="M6 21h12c1.1 0 2-.9 2-2V7H4v12c0 1.1.9 2 2 2zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <Lottie
              animationData={emptyCart}
              loop={true}
              style={{
                width: "20rem",
                height: "20rem",
              }}
              className="w-[20rem] sm:mt-[-2rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
            />
            <p className="text-red-400 text-lg text-center sm:text-[0.9rem]">
              No items in your cart
            </p>
          </>
        )}

        {/* {allProducts.length > 0 && (
          <div className="flex justify-center items-center mt-2">
            <button
              className="bg-red-600 text-white text-sm p-2 w-[30%] rounded-lg shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
              onClick={() => dispatch(clearCart())}
            >
              Clear Cart
            </button>
          </div>
        )} */}
      </div>
      {allProducts.length > 0 && (
        <div className="md:w-full basis-[18%] max-h-[500px] w-4/12 md:mt-4 pt-[2rem] right-bar flex-1 rounded-lg  md:mt-0 ">
          {/* <div className="flex flex-col space-y-4 mb-4">
            <div className="flex items-center space-x-4 mr-5">
              <label htmlFor="coupon_code" className="sr-only">
                Coupon:
              </label>
              <input
                type="text"
                name="coupon_code"
                className="input-text p-[0.8rem] text-sm border border-gray-300 rounded flex-grow"
                placeholder="Coupon code"
              />
              <button
                type="submit"
                className="bg-primary  md:p-2 text-xs text-white p-[0.8rem] rounded mr-2"
                name="apply_coupon"
              >
                Apply
              </button>
            </div>
          </div> */}
          <div>
            <p className="mt-4 text-sm">
              <strong className="text-[1rem] mb-[0.6rem] sm:block">
                Shipping Address:
                <br />
              </strong>
              {userDetail.addresses.length > 0 && (
                <div>
                  <span className=" text-[15px]">
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ].name + ", "}
                  </span>
                  <span className=" text-[15px]">
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.mobileNumber + ", "}
                    ,
                  </span>
                  <span>
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.address1 + ", "}
                    ,
                  </span>
                  <span>
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.address2 + ", "}
                  </span>
                  <span>
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.city + ", "}
                  </span>
                  <span>
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.state + ", "}
                  </span>
                  <span>
                    {userDetail.addresses[
                      localStorage.getItem("selectedAddressIndex")
                        ? Number(localStorage.getItem("selectedAddressIndex"))
                        : 0
                    ]?.postalCode + ", "}
                  </span>
                  <span>
                    {
                      userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.country
                    }
                  </span>
                </div>
              )}

              <Link to="/list-of-address" className="text-blue-700">
                Change Address
              </Link>
            </p>
            <div className="mt-[0.8rem]">
              <p className="font-bold">Payment Mode :</p>
              <label className="cursor-pointer text-blue-600 block mt-[0.5rem] text-[0.90rem] text-sm font-[450]">
                <input
                  type="radio"
                  name="payment-method"
                  value="CC Avenue"
                  checked={paymentMethod === "CC Avenue" ? true : false}
                  onChange={handlePaymentMethodChange}
                  className="mr-[0.4rem] h-[0.68rem]"
                />
                CC Avenue powered by HDFC
              </label>
            </div>
            <br />
            <div className="flex justify-end">
              <div className="w-full woocommerce-shipping-calculator">
                {showShippingCalculator && (
                  <section className="w-full shipping-calculator-form mt-4 bg-gray-100 p-4 rounded-lg">
                    <div className="w-full form-row form-row-wide mb-4">
                      <label
                        htmlFor="calc_shipping_country"
                        className="block text-sm"
                      >
                        Country / region:
                      </label>
                      <select
                        name="calc_shipping_country"
                        id="calc_shipping_country"
                        className="country_to_state country_select w-full p-2 border rounded"
                      >
                        <option value="default">
                          Select a country / region…
                        </option>
                        <option value="US" selected>
                          India
                        </option>
                        {/* Other options */}
                      </select>
                    </div>
                    <div className="w-full form-row form-row-wide mb-4">
                      <label htmlFor="calc_shipping_state" className="block">
                        State
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <select
                        name="calc_shipping_state"
                        className="state_select w-full p-2 border rounded"
                        id="calc_shipping_state"
                        data-placeholder="State / County"
                      >
                        <option value="">Select an option…</option>
                        <option value="CA">Haryana</option>
                        {/* Other options */}
                      </select>
                    </div>
                    <div className="form-row form-row-wide mb-4">
                      <label htmlFor="calc_shipping_city" className="block">
                        Town / City
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <input
                        type="text"
                        className="input-text w-full p-2 border rounded"
                        placeholder="City"
                        name="calc_shipping_city"
                        id="calc_shipping_city"
                      />
                    </div>
                    <div className="form-row form-row-wide mb-4">
                      <label htmlFor="calc_shipping_postcode" className="block">
                        ZIP Code
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <input
                        type="text"
                        className="input-text w-full p-2 border rounded"
                        placeholder="Postcode / ZIP"
                        name="calc_shipping_postcode"
                        id="calc_shipping_postcode"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        name="calc_shipping"
                        value="1"
                        className="button bg-blue-600 text-white px-4 py-2 rounded"
                      >
                        Update
                      </button>
                    </div>
                    <input
                      type="hidden"
                      id="woocommerce-shipping-calculator-nonce"
                      name="woocommerce-shipping-calculator-nonce"
                      value="545b8bb59e"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      value="/electronic/cart/"
                    />
                  </section>
                )}
              </div>
            </div>
          </div>

          <div>
            <p className="flex justify-between text-sm mb-3">
              <span>Subtotal</span>
              <span>₹{subtotal.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            {/* <p className="flex justify-between text-sm mb-3">
         <span>Discount (6%)</span>
         <span>-₹{discount.toFixed(2)}</span>
       </p> */}
            {/* <hr className="mb-3" /> */}
            <p className="flex justify-between text-sm mb-3">
              <span>Shipping Charges</span>
              <span>
                <span className="line-through">
                  ₹{shippingCharges.toFixed(2)}
                </span>
                <span className="ml-[0.5rem]"> ₹0</span>
              </span>
            </p>
            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span>Total</span>
              <span>₹{subtotal.toFixed(2)}</span>
            </p>
          </div>
          <p
            onClick={handleClickToCheckout}
            className="w-[80%] text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-lg text-md w-84 m-auto relative"
          >
            Checkout
            {isLoadingForCheckingOut && (
              <div className="absolute  sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center  sm:left-[32px] top-[-2px]">
                <Loader />
              </div>
            )}
          </p>

          <br />
        </div>
      )}
    </div>
  );
};

export default MobileCart;

import { useDispatch, useSelector } from "react-redux";
import AccountSidebar from "../SideBar";
import { setUser, UserInfo } from "../../../slice/UserSlice";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DeliveryAddress from "../../deliveryAddress/DeliveryAddress";
import DeleteDialogue from "../../deleteDialogue/DeleteDialogue";
import { DeleteRequest } from "../../../api/Request";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";
import { AppDispatch } from "../../../store/store";

const AddressList = () => {
  const userDetail: any = useSelector(UserInfo);
  const [showAddressForm, setShowAddAddress] = useState<Boolean>(false);
  const [isLoadingForDelete, setIsLoadingForDelete] = useState<boolean>(false);
  const [indexForDelete, setIndexForDelete] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();

  // State to manage the selected address
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<any>(null);

  // Load selected address index from local storage on component mount
  useEffect(() => {
    const storedIndex = localStorage.getItem("selectedAddressIndex");
    if (storedIndex) {
      setSelectedAddressIndex(Number(storedIndex));
    } else {
      setSelectedAddressIndex(0); // Default to first address if available
    }
  }, [userDetail.addresses]);

  // Update local storage whenever the selected address changes
  useEffect(() => {
    if (selectedAddressIndex !== null) {
      localStorage.setItem(
        "selectedAddressIndex",
        selectedAddressIndex.toString()
      );
    } else {
      localStorage.removeItem("selectedAddressIndex"); // Clear if no address is selected
    }
  }, [selectedAddressIndex]);

  const handleSelectAddress = (index: number) => {
    setSelectedAddressIndex(index);
  };

  const AddAddressForm = () => {
    setShowAddAddress(true);
  };

  const ShowDeleteAddress = (index: number) => {
    setIndexForDelete(index);
  };

  const handleDeleteAddress = async (index: number) => {
    setIsLoadingForDelete(true);
    try {
      let response = await DeleteRequest(`delete-address/${indexForDelete}`);
      if (response.status === 200) {
        setIndexForDelete(null); // Reset index for delete
        if (response.data.addresses.length > 0) {
          setSelectedAddressIndex(() => 0);
          localStorage.setItem("selectedAddressIndex", "0");
        }

        dispatch(
          setUser({
            name: userDetail.name || "",
            number: userDetail.number || "",
            gender: userDetail.gender || "",
            email: userDetail.email || "",
            addresses: response.data.addresses,
            userIsLoggedIn: true,
          })
        );
        showSuccessToast("Deleted Successfully");
      }
    } catch (error) {
      showErrorToast("Error in Deleting the address");
    }
    setIsLoadingForDelete(false);
  };

  return (
    <div className="flex mt-[8rem] items-start justify-center flex-wrap mb-[1rem] sm:px-[0.7rem]">
      <div className="basis-[20%] sm:hidden lg:basis-[100%] mt-[1rem] border-r-[1px] border-r-[#ddd]">
        <AccountSidebar />
      </div>
      {showAddressForm && (
        <DeliveryAddress
          showAddressForm={showAddressForm}
          setShowAddAddress={setShowAddAddress}
        />
      )}

      {indexForDelete != null ? (
        <DeleteDialogue
          handleDeleteAddress={handleDeleteAddress}
          setIndexForDelete={setIndexForDelete}
          isLoadingForDelete={isLoadingForDelete}
        />
      ) : null}

      <div className="flex-1 p-4 sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full">
        <div className="w-[90%] h-full bg-white rounded p-2 mx-auto sm:w-[93%] sm:p-0">
          <div className="flex justify-between items-start">
            <h1 className="font-medium text-2xl sm:text-lg mb-6 sm:mb-3">
              Your Delivery Addresses
            </h1>
            <span
              onClick={AddAddressForm}
              className="bg-primary cursor-pointer text-white h-[30px] w-[30px] rounded-[50%] flex justify-center items-center text-xl"
            >
              +
            </span>
          </div>
          {userDetail?.addresses && userDetail?.addresses.length > 0 && (
            <div className="mb-4">
              <h3 className="font-semibold">Shipping Address:</h3>
              <p>
                {userDetail.addresses[selectedAddressIndex]?.name + ", "}
                {userDetail.addresses[selectedAddressIndex]?.mobileNumber +
                  ", "}
                {userDetail.addresses[selectedAddressIndex]?.address1 + ", "}
                {userDetail.addresses[selectedAddressIndex]?.address2 + ", "}
                {userDetail.addresses[selectedAddressIndex]?.city + ", "}
                {userDetail.addresses[selectedAddressIndex]?.state + ", "}
                {userDetail.addresses[selectedAddressIndex]?.postalCode + ", "}
                {userDetail.addresses[selectedAddressIndex]?.country}
              </p>
            </div>
          )}
          <ul>
            {userDetail.addresses?.length > 0 ? (
              userDetail.addresses.map((address: any, index: number) => {
                const isSelected = index === selectedAddressIndex; // Check if this address is selected
                return (
                  <div key={index}>
                    <li className="items-center">
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleSelectAddress(index)} // Update selection on checkbox change
                        className="mr-2 cursor-pointer"
                      />
                      <span>{index + 1}. </span>
                      <span className="font-semibold text-[15px]">
                        {address?.name + ", "}
                      </span>
                      <span className="font-semibold text-[15px]">
                        {address?.mobileNumber + ", "}
                      </span>
                      <span>{address?.address1 + ", "} </span>
                      <span>{address?.address2 + ", "} </span>
                      <span>{address?.city + ", "} </span>
                      <span>{address?.state + ", "} </span>
                      <span>{address?.postalCode + ", "} </span>
                      <span>{address?.country + ", "} </span>
                      <p className="text-blue-500 cursor-pointer text-sm inline-block">
                        {"Edit Address" + ",  "}
                      </p>
                      <p
                        onClick={() => ShowDeleteAddress(index)}
                        className="text-blue-500 cursor-pointer text-sm inline-block"
                      >
                        Delete Address
                      </p>
                    </li>
                    <hr className="my-[0.9rem]" />
                  </div>
                );
              })
            ) : (
              <p className="text-center text-red-500 mt-[1rem]">
                No Addresses Found
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddressList;

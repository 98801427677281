import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  page,
  totalItemForProduct,
  totalPageForProduct,
} from "../slice/ProductSlice";

const Pagination = (props: any) => {
  let { pageNumber, totalPages, totalItems } = props;
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const [mainUrl, setMainUrl] = useState("");

  // useEffect(() => {
  //   if (query.get("sub-category-id")) {
  //     setMainUrl(() => `/order-info?page=${pageNumber}`);
  //   }
  // }, [query.get("page")]);

  const giveUrlAccToPage = (index: number) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    searchParams.set("page", index.toString());

    const newUrl = `${currentUrl.origin}${
      currentUrl.pathname
    }?${searchParams.toString()}`;

    return newUrl;
  };

  return (
    <div className="flex m-auto justify-center ">
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px text-sm">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1}>
              <Link
                to={giveUrlAccToPage(index + 1)}
                className={
                  pageNumber == index + 1
                    ? "flex items-center justify-center text-white px-3 h-8 leading-tight text-gray-500  border border-gray-300 bg-primary"
                    : "flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100"
                }
                style={{
                  borderRadius:
                    index === 0
                      ? "0.375rem 0 0 0.375rem"
                      : index === totalPages - 1
                      ? "0 0.375rem 0.375rem 0"
                      : "0",
                }}
              >
                {index + 1}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;

import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading, IsLoggedIn, signUp, UserInfo } from "../../slice/UserSlice";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import InputText from "../../common/component/InputText";
import Loader from "../../common/component/loader/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineVerified } from "react-icons/md";

// Interface for form data
interface FormData {
  addresses: any;
  username: string;
  number: string;
  password: string;
  otp: string | "";

  error: {
    username: string;
    password: string;
    number: string;
    otp: string;
  };
}

// Interface for form errors
interface Errors {
  name?: string;
  mobile?: string;
  password?: string;
  otp?: string;
}

const Register: React.FC = () => {
  const [timer, setTimer] = useState(0);
  const location = useLocation();
  const [isLoadingForVerifyingOtp, setIsLoadingForVerifyingOtp] =
    useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userInfo, setUserInfo] = useState<FormData>({
    username: "",
    number: "",
    password: "",
    otp: "",
    addresses: [],
    error: {
      username: "",
      password: "",
      number: "",
      otp: "",
    },
  });
  const userDetail: any = useSelector(UserInfo);
  const [otpMsg, setOtpMsg] = useState<string>("");
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const navigate = useNavigate();
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const [isLoadingForOtp, setIsLoadingForOtp] = useState<Boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = useState<Errors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Countdown timer effect
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timer]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorMessage = "";
    switch (name) {
      case "username":
        if (value.trim() === "") {
          errorMessage = "Name is required.";
        } else if (value.length < 3) {
          errorMessage = "Name must be at least 3 characters.";
        } else {
          errorMessage = "";
        }
        break;
      case "password":
        if (value.trim() === "") {
          errorMessage = "Password is required.";
        } else if (value.length < 6) {
          errorMessage = "Password must be at least 6 characters.";
        } else {
          errorMessage = "";
        }
        break;
      case "number":
        // Regular express6ion for Indian phone numbers
        const phoneNumberPattern = /^[6789]\d{9}$/;
        if (value.trim() === "") {
          errorMessage = "Phone number is required.";
        } else if (value.length !== 10) {
          errorMessage = "Phone number must be of 10 digits only.";
        } else if (!phoneNumberPattern.test(value)) {
          errorMessage = "Please enter a valid phone number.";
        } else {
          errorMessage = "";
        }
        break;
      case "otp":
        setOtp(() => value);
        break;
      default:
        break;
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
      error: {
        ...prevState.error,
        [name]: errorMessage,
      },
    }));
  };

  const handleSignUp = async () => {
    setIsLoading(() => true);
    let updatedUserInfo = { ...userInfo };
    if (
      userInfo.username &&
      userInfo.password &&
      userInfo.number &&
      isOtpVerified
    ) {
      // Check for any errors in the user input
      if (
        !userInfo.error.username &&
        !userInfo.error.password &&
        !userInfo.error.number
      ) {
        // Dispatch the signUp action
        try {
          await dispatch(
            signUp({
              username: userInfo.username,
              password: userInfo.password,
              number: userInfo.number,
              otp: userInfo.otp,
              addresses: userInfo.addresses,
            })
          ).unwrap(); // unwrap to get the resolved value or throw an error
        
        } catch (error: any) {
          // Handle errors from the signUp thunk
          await showErrorToast(error?.message || "Failed to sign up");
        }
      }
    } else {
      // Update error messages if fields are empty
      if (!userInfo.username) {
        updatedUserInfo.error.username = "required";
      }
      if (!userInfo.password) {
        updatedUserInfo.error.password = "required";
      }
      if (!userInfo.number) {
        updatedUserInfo.error.number = "required";
      }

      if (userInfo.number && !isOtpVerified) {
        updatedUserInfo.error.number = "Please Verify Your Number";
      }

      setUserInfo(() => updatedUserInfo);
    }
    setIsLoading(() => false);
  };

  

  // Handle sending OTP
  const handleSendOtp = async (e: React.MouseEvent) => {
    e.preventDefault();
    let { number } = userInfo;
    const phoneNumberPattern = /^[6789]\d{9}$/;
    let errorMessage = "";

    if (number.trim() === "") {
      errorMessage = "Phone number is required.";
    } else if (number.length !== 10) {
      errorMessage = "Phone number must be of 10 digits only.";
    } else if (!phoneNumberPattern.test(number)) {
      errorMessage = "Please enter a valid phone number.";
    } else {
      errorMessage = "";
    }

    setUserInfo((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        number: errorMessage,
      },
    }));

    if (errorMessage === "") {
      setIsLoadingForOtp(() => true);
      await PostRequest("send-reg-otp", { number })
        .then((response) => {
          if (response.status === 200) {
            setIsButtonDisabled(() => true);
            setOtpSent(() => true);
            showSuccessToast("Otp has been successfully sent");
            setTimer(() => 30);
          }
        })
        .catch((error: any) => {
          showErrorToast(error.response.data.message);
          setOtpMsg(() => "");
        });
      setIsLoadingForOtp(() => false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    if (userIsLoggedIn) {
      if (redirect === "cart") {
        navigate("/cart");
      } else {
        navigate("/");
      }
    }
  }, [userIsLoggedIn, location.search]);

  const verifyPhoneNumber = async () => {
    setIsLoadingForVerifyingOtp(() => true);
    let newUserInfo = { ...userInfo };
    try {
      let response: any = await PostRequest("verify-otp", {
        otp,
        number: userInfo?.number,
      });

      if (response.status === 200) {
        newUserInfo.error.number = "";
        setOtp("");

        setUserInfo(() => userInfo);
        setIsOtpVerified(true);
        setOtpMsg("");

        setIsLoadingForOtp(false);
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
      setOtpMsg(() => "");
    }
    setIsLoadingForVerifyingOtp(() => false);
  };

  return (
    <div className="flex items-center justify-center h-[80vh] mt-[5rem]">
      <section className="flex flex-col items-center pt-6 w-[40%]  sm:w-[90%] ">
        <div className="bg-white rounded-lg shadow-lg  w-full max-w-sm  transition-transform transform hover:scale-105">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-[1rem]">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-lg ">
              Create your account
            </h1>
            <div className="space-y-4 md:space-y-6">
              <label className="block mt-4 text-left text-gray-700 ">
                Name:
                <InputText
                  type="text"
                  name="username"
                  placeholder="Your Full Name"
                  value={userInfo.username}
                  onHandleChange={handleChange}
                  disabled={false}
                />
                <p className="text-red-500 text-xs italic">
                  {userInfo.error.username}
                </p>
              </label>

              <div className="flex items-center relative">
                <div className="flex-grow">
                  <label className="block text-left text-gray-700 ">
                    Phone Number:
                    <InputText
                      type="number"
                      name="number"
                      placeholder="Your number"
                      value={userInfo.number}
                      onHandleChange={handleChange}
                      disabled={isOtpVerified}
                    />
                    <p className="text-red-500 text-xs italic">
                      {userInfo.error.number}
                    </p>
                    {otpMsg && (
                      <p className="text-green-400 text-sm">{otpMsg}</p>
                    )}
                  </label>
                </div>

                {isLoadingForOtp ? (
                  <div className="absolute right-[42px] top-[20%] z-10">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {isOtpVerified ? (
                      <MdOutlineVerified
                        className="absolute right-[5px] top-[60%] text-green-500"
                        title="OTP Verified"
                      />
                    ) : (
                      <p
                        className={
                          isButtonDisabled
                            ? "cursor-not-allowed text-sm absolute right-[10px] top-[56%] font-medium text-[#3f0e9e] ml-2"
                            : "text-sm absolute right-[10px] top-[56%] font-medium cursor-pointer text-[#3f0e9e] hover:underline dark:text-[#3f0e9e] ml-2"
                        }
                        onClick={!isButtonDisabled ? handleSendOtp : undefined}
                      >
                        {isButtonDisabled
                          ? `Resend OTP in ${timer}s`
                          : "Get OTP"}
                      </p>
                    )}
                  </>
                )}

                {/* {isOtpVerified && !isLoadingForOtp ? (
                  <FaCheckCircle
                    className="absolute right-[5px] top-[60%]  text-green-500"
                    title="OTP Verified"
                  />
                ) : (
                  <p
                    className={
                      isButtonDisabled
                        ? "cursor-not-allowed text-sm absolute right-[10px] top-[56%] font-medium text-[#3f0e9e] ml-2"
                        : "text-sm absolute right-[10px] top-[56%] font-medium cursor-pointer text-[#3f0e9e] hover:underline dark:text-[#3f0e9e] ml-2"
                    }
                    onClick={!isButtonDisabled ? handleSendOtp : undefined}
                  >
                    {isButtonDisabled ? `Resend OTP in ${timer}s` : "Get OTP"}
                  </p>
                )}
                {isLoadingForOtp && (
                  <div>
                    <Loader />
                  </div>
                )} */}
              </div>
              {!isOtpVerified && otpSent && (
                <label className="block mt-4 text-left text-gray-700 relative">
                  OTP:
                  <InputText
                    type="number"
                    name="otp"
                    placeholder="Enter the otp"
                    value={userInfo.otp}
                    onHandleChange={handleChange}
                    disabled={isOtpVerified}
                  />
                  {isLoadingForVerifyingOtp ? (
                    <div className="absolute right-[37px] top-[20%]">
                      <Loader />
                    </div>
                  ) : (
                    <p
                      className={
                        "text-sm absolute right-[10px] top-[56%] font-medium cursor-pointer text-[#3f0e9e] hover:underline dark:text-[#3f0e9e] ml-2"
                      }
                      onClick={verifyPhoneNumber}
                    >
                      Verify
                    </p>
                  )}
                  {isOtpVerified && (
                    <FaCheckCircle
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 text-green-500"
                      title="OTP Verified"
                    />
                  )}
                </label>
              )}
              {/* {!isOtpVerified && otpSent && (
                <label className="block mt-4 text-left text-gray-700 relative">
                  OTP:
                  <InputText
                    type="number"
                    name="otp"
                    placeholder="Enter the otp"
                    value={userInfo.otp}
                    onHandleChange={handleChange}
                    disabled={isOtpVerified}
                  />
                  {isOtpVerified ? (
                    <FaCheckCircle
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 text-green-500"
                      title="OTP Verified"
                    />
                  ) : (
                    <p
                      className={
                        "text-sm absolute right-[10px] top-[56%] font-medium cursor-pointer text-[#3f0e9e] hover:underline dark:text-[#3f0e9e] ml-2"
                      }
                      onClick={verifyPhoneNumber}
                    >
                      Verfiy
                    </p>
                  )}

                  {isLoadingForVerifyingOtp && (
                    <p>
                      <Loader />
                    </p>
                  )} */}
              <p className="text-red-500 text-xs italic">
                {userInfo.error.otp}
              </p>

              <label className="block mt-4 text-left text-gray-700 ">
                Password:
                <InputText
                  type="password"
                  name="password"
                  placeholder="enter your password"
                  value={userInfo.password}
                  onHandleChange={handleChange}
                  disabled={false}
                />
                <p className="text-red-500 text-xs italic">
                  {userInfo.error.password}
                </p>
              </label>
              <button
                type="submit"
                onClick={handleSignUp}
                className="w-full text-white bg-primary relative hover:bg-[#3f0e9e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                // disabled={isLoading}
              >
                {isLoading ? (
                  <div
                    className="absolute 
                  top-[-16px]  right-[39%]"
                  >
                    <Loader />
                  </div>
                ) : (
                  ""
                )}
                Sign up
              </button>
              <Link
                to={`/login${location.search ? `${location.search}` : ""}`}
                className="text-blue-600 text-center flex justify-center text-sm mt-[0.5rem]"
              >
                Already a registered user? log in
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showSuccessToast } from "../utils/toast";
import { RootState } from "../store/store";
import { product } from "./ProductSlice";

interface Product {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: string;
  store: string;
  subtotal: number;
}

interface CartState {
  items: [];
  products: any;
  subtotal: number;
  discount: number;
  shippingCharges: number;
  total: number;
}

const initialState: CartState = {
  products: [],
  subtotal: 0,
  discount: 0,
  shippingCharges: 150,
  total: 0,
  items: [], // Ensure items is initialized appropriately
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    calculateTotal: (state) => {
      // state.subtotal = state.products.reduce(
      //   (acc, product) => acc + product.subtotal,
      //   0
      // );
      // state.discount = state.subtotal * 0.06;
      state.total = state.subtotal;
    },
    addProduct: (state, action: PayloadAction<any>) => {
      let subTotalPrice: number = 0;
      const existingProduct = state.products.find(
        (product: any) => product._id === action.payload._id
      );

      if (
        existingProduct &&
        existingProduct.quantityByUser !== action.payload.quantityByUser
      ) {
        existingProduct.quantityByUser = action.payload.quantityByUser;
        existingProduct.total =
          action.payload.quantityByUser * Number(action.payload.salePrice);
        state.products = state.products.map((product: any) => {
          if (product._id !== existingProduct._id) {
            return product;
          } else {
            return existingProduct;
          }
        });
        showSuccessToast("Product updated in cart");
      }

      if (!existingProduct) {
        state.products.push(action.payload);
        showSuccessToast("Product added to cart");
      }
      //
      state.products.forEach((product: any) => {
        subTotalPrice += Number(product.total);
      });
      state.subtotal = subTotalPrice;
      localStorage.setItem("cart", JSON.stringify(state.products));
      localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
    },
    updateQuantity: (
      state,
      action: PayloadAction<{ id: string; amount: string }>
    ) => {
      const product = state.products.find(
        (product: any) => product._id === action.payload.id
      );

      if (product) {
        // Update the quantity based on the action amount
        if (action.payload.amount === "+1") {
          product.quantityByUser += 1; // Assuming you want to update quantityByUser
        } else if (action.payload.amount === "-1") {
          product.quantityByUser = Math.max(0, product.quantityByUser - 1); // Prevent negative quantity
        }

        // Update the total price for the product
        product.total = product.quantityByUser * product.salePrice;

        // Update the subtotal
        state.subtotal = state.products.reduce(
          (acc: number, product: any) => acc + product.total,
          0
        );

        // Optionally, update local storage
        localStorage.setItem("cart", JSON.stringify(state.products));
        localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
      }
    },
    removeProduct: (state, action: PayloadAction<number>) => {
      // Find the product that is being removed

      const productToRemove = state.products.find((product: any) => {
        return product._id == action.payload;
      });

      if (productToRemove) {
        // Subtract the salePrice of the removed product from the subtotal
        state.subtotal -= productToRemove.total;

        // Remove the product from the products array
        state.products = state.products.filter(
          (product: any) => product._id !== action.payload
        );
      }
    },
    clearCart: (state) => {
      state.products = [];
      state.subtotal = 0;
      localStorage.removeItem("cart");
    },
    toggleShippingCalculator: (state) => {
      state.shippingCharges = state.shippingCharges === 150 ? 0 : 150;
    },
  },
});

export const {
  calculateTotal,
  addProduct,
  updateQuantity,
  removeProduct,
  clearCart,
  toggleShippingCalculator,
} = cartSlice.actions;

export const totalCartItem = (state: RootState) => {
  return state.cart.products.length;
};
export const allProduct = (state: RootState) => {
  return state.cart.products;
};
export default cartSlice.reducer;

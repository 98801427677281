const Description = (props: any) => {
  let { product } = props;
  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-2 sm:text-sm">
        Product Description
      </h2>
      <ul className="list-disc list-inside text-gray-700 text-gray-700">
        <li className="list-none sm:text-sm">{product?.description}</li>
      </ul>

      {/* <div className="flex flex-col items-center justify-center py-12">
        <h2 className="text-xl font-bold mb-4">From The Manufacturer</h2>
        <div
          className="celwidget aplus-module 3p-module-b aplus-standard"
          data-csa-c-id="9j6v7u-fayz7g-wsci4o-i5u9ry"
          data-cel-widget="aplus-3p-module-b"
        >
          <div className="aplus-module-wrapper aplus-3p-fixed-width">
            <img 
              alt="FTL"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/6af05482-ff3e-46e4-9dd1-20727e67372c.__CR0,0,970,600_PT0_SX970_V1___.jpg"
              className="a-spacing-base"
              style={{ maxWidth: "100%", height: "80%" }} // Optional: Adjust image styles as needed
            />
          </div>
        </div>
      </div> */}
      {/*/////////////////////////////////////////////////// */}

      {/* <div className="flex flex-col items-center justify-center">
        <h2 className="text-xl font-bold mb-4">Features</h2>
        <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg">
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="PM 2.5 Filter"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/c904a19b-c7ea-4c41-953b-2e694fb15d8e.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              It is capable to trap fine air particles up to 2.5 microns
              resulting in clean and pure inside air.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Power Chill Operation"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/f3a5ac1b-8e4e-42e5-a6bc-0e24c5be74fe.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              Power chill operation of your AC ensures instant and faster
              cooling even in extreme heat conditions. It cools 20% faster than
              the normal mode providing instant relief from the heat.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Econo mode"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/4c1f0e1e-f83d-4449-a85b-68c1ca6a0cc2.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              Econo mode enables efficient operation by limiting the maximum
              power consumption. It is useful when using the air-conditioner and
              other electrical devices simultaneously on a shared electrical
              circuit. This mode helps you in reducing your electricity bills.
            </p>
          </div>
        </div>
      </div> */}

      {/* /////////////////////////////////////////// */}
      {/* <div className="flex flex-col items-center justify-center">
        <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg py-4">
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="100% Copper"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/64c3ffa8-1f36-43b1-b5f8-d6c7a32348ef.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              Daikin air conditioners Condensers are equipped with 100% copper
              coils which functions as the backbone for robust air conditioning
              system.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Stabilizer free"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/39bd64ae-32e4-493c-96b1-d806b7467ea5.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              This machine is equipped with Stabilizer free which removes its
              dependency from external stabilizer.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="R-32 Refrigerant"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/66f58b06-a522-41e1-85bf-414369d1bb2b.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              R32 (Green Refrigerant) has zero Ozone Depletion Potential (ODP)
              and Modified Global Warming Potential (GWP) of 472, compared to
              R410A’s Modified GWP of 2,027. Also R32 is a single component
              refrigerant, which makes it easy to recycle. It is because of
              these reasons that R32 offers the lowest total emissions and best
              overall life cycle climate performance.
            </p>
          </div>
        </div>
      </div> */}

      {/* /////////////////////////////////////////////// */}

      {/* <div className="flex flex-col items-center justify-center">
        <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg py-2">
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Self diagnosis"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/c8048eeb-3885-49cf-8b2c-ebfd5953ad80.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              The machine auto detects the error and shows the related code on
              the remote screen (After pressing Key mentioned in manual). Now
              you can easily self diagnose the error and report it to your
              Daikin authorized service center for a quick resolution.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Dry Mode"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/8f2aa6c4-6697-4c3d-a15c-8ac634043272.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              Dry Mode Function of this AC ensures automatic dehumidification by
              reducing the excessive humidity in rainy days for utmost comfort.
            </p>
          </div>
          <div className="flex flex-col items-center p-4 border rounded-lg w-80 h-100">
            <img 
              alt="Dual flap"
              src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/96212af9-0d96-423f-a627-097eb2519726.__CR0,0,300,300_PT0_SX300_V1___.jpg"
              className="mb-4"
            />
            <p className="flex-grow">
              The Power Airflow Dual Flaps of the AC ensures less air cutting
              noise and provides uniform cooling.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Description;

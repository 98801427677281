import React from "react";

const AdditionalInfo = (props: any) => {
  let { product } = props;
  const details = [
    { attribute: "Brand", value: "Daikin" },
    { attribute: "Model", value: "FTL28U" },
    { attribute: "Energy Efficiency", value: "3 Star" },
    { attribute: "Capacity", value: "0.8 Tons" },
    {
      attribute: "Annual Energy Consumption",
      value: "548.84 Kilowatt Hours Per Year",
    },
    { attribute: "Noise Level", value: "32 dB" },
    { attribute: "Installation Type", value: "Split System" },
    { attribute: "Part Number", value: "FTL28U" },
    { attribute: "Form Factor", value: "Mini-Split" },
    {
      attribute: "Special Features",
      value:
        "Inverter Compressor, Dry Mode, Self-Diagnosis, Air Purification Filter, Dehumidifier, Dust Filter, Fast Cooling",
    },
    { attribute: "Colour", value: "White" },
    { attribute: "Control Console", value: "Remote Control" },
    { attribute: "Voltage", value: "230 Volts" },
    { attribute: "Wattage", value: "709 Watts" },
    { attribute: "Certification", value: "Energy Star" },
    {
      attribute: "Material",
      value: "IDU: HIPS (High grade plastic) ; ODU: HDZP Steel Sheet",
    },
    {
      attribute: "Included Components",
      value:
        "1 Indoor Unit, 1 Outdoor Unit, 1 Remote Control, User manual, Warranty Card & 3m Copper wire/ Interconnecting pipe",
    },
    { attribute: "Batteries Included", value: "Yes" },
    { attribute: "Batteries Required", value: "Yes" },
    { attribute: "Battery Cell Type", value: "Zinc" },
    {
      attribute: "Manufacturer",
      value: "Daikin Airconditioning India Pvt. Ltd.",
    },
    { attribute: "Country of Origin", value: "India" },
  ];

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-2">Additional Information</h2>
      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full leading-normal">
          <tbody>
            {details.map((detail, index) => (
              <tr key={index}>
                <th className="py-2 px-4 bg-gray-100 text-gray-600 font-bold uppercase text-sm border-b border-gray-200">
                  {detail.attribute}
                </th>
                <td className="py-2 px-4 text-gray-900 border-b border-gray-200">
                  {detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdditionalInfo;

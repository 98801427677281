import React, { useState, useEffect, useCallback } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  updateQuantity,
  removeProduct,
  clearCart,
  toggleShippingCalculator,
  allProduct,
  calculateTotal,
} from "../../slice/CartSlice";
import MobileCart from "./MobileCart";
import Lottie from "lottie-react";
import carticon from "../../loti/carticon.json";
import emptyCart from "../../loti/emptyCart.json";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { decrementQuantity, incrementQuantity } from "../../slice/ProductSlice";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import Loader from "../../common/loader/Loader";
import AddressList from "../userSetting/userDetail/AddressList";

const ShoppingCart: React.FC = () => {
  const { products, subtotal, discount, shippingCharges, total } = useSelector(
    (state: RootState) => state.cart
  );
  const [isLoadingForCheckingOut, setIsLoadingForCheckingOut] =
    useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [paymentMethod, setPaymentMethod] = useState<string>("CC Avenue");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showShippingCalculator, setShowShippingCalculator] = useState(false);
  const allProducts = useSelector(allProduct);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const [shippingAddress, setShippingAddress] = useState<any>();
  const { addresses } = useSelector(UserInfo);

  const handleClickToCheckout = async () => {
    if (userIsLoggedIn == false) {
      navigate(`/register?redirect=cart`);
    } else if (!userDetail.email) {
      navigate(`/profile?redirect=cart`);
      return showErrorToast("Please add your email");
    }
    if (userIsLoggedIn && addresses.length === 0) {
      navigate(`/list-of-address?redirect=cart`);
      return showErrorToast("Add your delivery address");
    }
    if (userIsLoggedIn && paymentMethod) await createOrder();
    if (userIsLoggedIn && !paymentMethod) showErrorToast("Select payment mode");
  };

  const handleIncrementForQuantity = (id: string, product: any) => {
    if (product.quantityByUser !== product.totalSotck) {
      dispatch(updateQuantity({ id, amount: "+1" }));
    }
  };

  const handleDecrementForQuantity = (id: string, product: any) => {
    if (product.quantityByUser > 1) {
      dispatch(updateQuantity({ id, amount: "-1" }));
    }
  };

  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(() => e.target.value);
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, []);

  const createOrder = async () => {
    try {
      setIsLoadingForCheckingOut(true);
      let allProductDetail = allProducts.flatMap((product: any) => {
        return { _id: product._id, quantity: product.quantityByUser };
      });
      let response = await PostRequest("createOrder", {
        payment_mode: paymentMethod,
        discount: 0,
        shippingCharge: 0,
        totalAmount: subtotal,
        productDetails: allProductDetail,
        shippingAddress,
      });

      if (response.status === 200) {
        window.location.href = await response?.data?.payment_links?.web;
      }
    } catch (error) {
      showErrorToast("Order Failed");
    } finally {
      setIsLoadingForCheckingOut(() => false);
    }
  };

  return (
    <div
      style={{ marginTop: "7rem" }}
      className={
        allProducts?.length > 0
          ? "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8"
          : "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col"
      }
    >
      <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-8rem] mt-[-1rem] sm:mt-[-1rem] w-[100%] flex items-center">
        <Lottie
          animationData={carticon}
          style={{
            width: "80px",
            height: "100px",
            marginRight: "-1.4rem",
          }}
        />
        Cart
      </h1>

      {allProducts?.length > 0 ? (
        <div className="flex flex-row md:flex-col flex-wrap justify-between">
          <div className="w-full basis-[70%] md:w-9/12 pr-4 border-[1.3px] md:border-none rounded-lg">
            <table className=" mx-2  min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Products
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subtotal
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {allProducts.map((product: any, index: number) => (
                  <tr key={product._id}>
                    <td className="px-6 py-4">
                      <Link to="#">
                        <img
                          className="w-24 h-24 md:w-16 md:h-16 sm:w-12 sm:h-12 object-contain"
                          src={product?.images[0]?.url}
                          alt={product.productName}
                        />
                      </Link>
                    </td>
                    <td className="px-6 py-4 md:w-1/3">
                      <Link
                        to="#"
                        className="text-sm font-medium text-gray-900 md:text-[12px] sm:text-[8px]"
                      >
                        {product.productName}
                      </Link>
                      {/* <dl className="text-sm text-gray-500">
                      <dt className="sr-only">Store</dt>
                      <dd>{product.store}</dd>
                    </dl> */}
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm font-medium text-gray-900">
                        ₹{product.salePrice}
                      </span>
                    </td>
                    <td className="px-2 py-4 flex-row ">
                      <button
                        className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={() =>
                          handleDecrementForQuantity(product._id, product)
                        }
                      >
                        <svg
                          className="flex-shrink-0 size-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M5 12h14"></path>
                        </svg>
                      </button>
                      <input
                        type="number"
                        className="w-8 text-center border-gray-300 rounded-md"
                        value={product?.quantityByUser}
                        readOnly
                      />
                      <button
                        className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={() =>
                          handleIncrementForQuantity(product._id, product)
                        }
                      >
                        <svg
                          className="flex-shrink-0 size-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5v14"></path>
                        </svg>
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm font-medium text-gray-900">
                        ₹{product.total}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <button
                        className="text-sm text-red-600 hover:text-red-900"
                        onClick={() => dispatch(removeProduct(product._id))}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className="px-6 py-4 text-right">
                    <button
                      className="ml-2 px-4 py-2 bg-red-600 text-white"
                      onClick={() => dispatch(clearCart())}
                    >
                      Clear Cart
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Sidebar Content */}
          <div className="md:w-full basis-[18%] max-h-[500px] w-4/12 md:mt-4  right-bar flex-1 rounded-lg  md:mt-0 border-[1px] ml-2 ">
            <div className="flex flex-col space-y-4 mb-4">
              {/* <div className="flex items-center space-x-4 mr-5">
                <label htmlFor="coupon_code" className="sr-only">
                  Coupon:
                </label>
                <input
                  type="text"
                  name="coupon_code"
                  className="input-text p-[0.8rem] text-sm border border-gray-300 rounded flex-grow"
                  placeholder="Coupon code"
                />
                <button
                  type="submit"
                  className="bg-primary  md:p-2 text-xs text-white p-[0.8rem] rounded mr-2"
                  name="apply_coupon"
                >
                  Apply
                </button>
              </div> */}
            </div>
            <div className="mb-4 mx-5">
              <p className="mt-4 text-sm">
                <strong className="text-[0.96rem] mb-[0.4rem]">
                  Shipping Address:
                  <br />
                </strong>
                {userDetail.addresses.length > 0 && (
                  <div>
                    <span className=" text-[15px]">
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ].name + ", "}
                    </span>
                    <span className=" text-[15px]">
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.mobileNumber + ", "}
                      ,
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.address1 + ", "}
                      ,{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.address2 + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.city + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.state + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.postalCode + ", "}{" "}
                    </span>
                    <span>
                      {
                        userDetail.addresses[
                          localStorage.getItem("selectedAddressIndex")
                            ? Number(
                                localStorage.getItem("selectedAddressIndex")
                              )
                            : 0
                        ]?.country
                      }{" "}
                    </span>
                  </div>
                )}

                <Link to="/list-of-address" className="text-blue-700">
                  Change Address
                </Link>
              </p>
              <div className="mt-[0.8rem]">
                <p className="font-bold">Payment Mode :</p>
                <label className="cursor-pointer text-blue-600 block mt-[0.5rem] text-[0.90rem] text-sm font-[450]">
                  <input
                    type="radio"
                    name="payment-method"
                    value="CC Avenue"
                    checked={paymentMethod === "CC Avenue" ? true : false}
                    onChange={handlePaymentMethodChange}
                    className="mr-[0.4rem] h-[0.68rem]"
                  />
                  CC Avenue powered by HDFC
                </label>
              </div>
              <br />
              <div className="flex justify-end">
                <div className="w-full woocommerce-shipping-calculator">
                  {showShippingCalculator && (
                    <section className="w-full shipping-calculator-form mt-4 bg-gray-100 p-4 rounded-lg">
                      <div className="w-full form-row form-row-wide mb-4">
                        <label
                          htmlFor="calc_shipping_country"
                          className="block text-sm"
                        >
                          Country / region:
                        </label>
                        <select
                          name="calc_shipping_country"
                          id="calc_shipping_country"
                          className="country_to_state country_select w-full p-2 border rounded"
                        >
                          <option value="default">
                            Select a country / region…
                          </option>
                          <option value="US" selected>
                            India
                          </option>
                          {/* Other options */}
                        </select>
                      </div>
                      <div className="w-full form-row form-row-wide mb-4">
                        <label htmlFor="calc_shipping_state" className="block">
                          State
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <select
                          name="calc_shipping_state"
                          className="state_select w-full p-2 border rounded"
                          id="calc_shipping_state"
                          data-placeholder="State / County"
                        >
                          <option value="">Select an option…</option>
                          <option value="CA">Haryana</option>
                          {/* Other options */}
                        </select>
                      </div>
                      <div className="form-row form-row-wide mb-4">
                        <label htmlFor="calc_shipping_city" className="block">
                          Town / City{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          className="input-text w-full p-2 border rounded"
                          placeholder="City"
                          name="calc_shipping_city"
                          id="calc_shipping_city"
                        />
                      </div>
                      <div className="form-row form-row-wide mb-4">
                        <label
                          htmlFor="calc_shipping_postcode"
                          className="block"
                        >
                          ZIP Code{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          className="input-text w-full p-2 border rounded"
                          placeholder="Postcode / ZIP"
                          name="calc_shipping_postcode"
                          id="calc_shipping_postcode"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          name="calc_shipping"
                          value="1"
                          className="button bg-blue-600 text-white px-4 py-2 rounded"
                        >
                          Update
                        </button>
                      </div>
                      <input
                        type="hidden"
                        id="woocommerce-shipping-calculator-nonce"
                        name="woocommerce-shipping-calculator-nonce"
                        value="545b8bb59e"
                      />
                      <input
                        type="hidden"
                        name="_wp_http_referer"
                        value="/electronic/cart/"
                      />
                    </section>
                  )}
                </div>
              </div>
            </div>

            <div className="m-4">
              <p className="flex justify-between text-sm mb-3">
                <span>Subtotal</span>
                <span>₹{subtotal.toFixed(2)}</span>
              </p>
              <hr className="mb-3" />
              {/* <p className="flex justify-between text-sm mb-3">
                <span>Discount (6%)</span>
                <span>-₹{discount.toFixed(2)}</span>
              </p> */}
              {/* <hr className="mb-3" /> */}
              <p className="flex justify-between text-sm mb-3">
                <span>Shipping Charges</span>
                <span>
                  <span className="line-through">
                    ₹{shippingCharges.toFixed(2)}
                  </span>
                  <span className="ml-[0.5rem]"> ₹0</span>
                </span>
              </p>
              <hr className="mb-3" />
              <p className="flex justify-between text-sm mb-3">
                <span>Total</span>
                <span>₹{subtotal.toFixed(2)}</span>
              </p>
            </div>
            <p
              onClick={handleClickToCheckout}
              className="w-[80%]  text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-md  text-md w-84 m-auto relative"
            >
              Proceed to Checkout
              {isLoadingForCheckingOut && (
                <div className="absolute top-[-18px] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] right-[21%]">
                  <Loader />
                </div>
              )}
            </p>

            <br />
          </div>
        </div>
      ) : (
        <>
          <Lottie
            animationData={emptyCart}
            loop={true}
            style={{
              width: "20rem",
              height: "20rem",
            }}
            className="w-[20rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
          />
          <p className="text-red-400 text-lg sm:text-[0.9rem]">
            No items in your cart
          </p>
        </>
      )}
    </div>
  );
};

export default ShoppingCart;

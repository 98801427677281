import React from "react";

const ReturnAndExchange: React.FC = () => {
  return (
    <div className="container mx-auto px-4 mt-[8rem] ">
      <main role="main" className="flex flex-col items-center mx-auto">
        <div className="w-full  blog-main">
          <div className="mb-4 mt-6">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Returns & Exchanges
            </h2>
            <p className="mb-4 text-base md:text-lg">
              Thank you for shopping at CayroShop.
            </p>
            <p className="mb-4 text-base md:text-lg">
              If, for any reason, you are not completely satisfied with a
              purchase, we invite you to review our policy on refunds and
              returns.
            </p>
            <h3 className="text-xl font-bold mb-4">
              Interpretation and Definitions:
            </h3>
            <p className="mb-4 text-base md:text-lg">
              <strong>Interpretation</strong>
              <br />
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <p className="mb-4 text-base md:text-lg">
              <strong>Definitions</strong>
              <br />
              For the purposes of this Return and Refund Policy:
              <ul className="list-disc list-inside mb-4 text-base md:text-lg">
                <li>
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to CayroShop.
                </li>
                <li>
                  <strong>Goods</strong> refer to the items offered for sale on
                  the Service.
                </li>
                <li>
                  <strong>Orders</strong> mean a request by you to purchase
                  Goods from us.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Website</strong> refers to CayroShop, accessible from{" "}
                  <a href="https://cayroshop.com/" className="text-blue-500">
                    https://cayroshop.com/
                  </a>
                </li>
                <li>
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mb-4">
              Your Order Cancellation Rights:
            </h3>
            <p className="mb-4 text-base md:text-lg">
              You are entitled to cancel your Order within 7 days without giving
              any reason for doing so.
            </p>
            <p className="mb-4 text-base md:text-lg">
              The deadline for cancelling an Order is 7 days from the date on
              which you received the Goods or on which a third party you have
              appointed, who is not the carrier, takes possession of the product
              delivered.
            </p>
            <p className="mb-4 text-base md:text-lg">
              In order to exercise your right of cancellation, you must inform
              us of your decision by means of a clear statement. You can inform
              us of your decision by:
            </p>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li>
                By email:{" "}
                <a href="mailto:info@cayroshop.com" className="text-blue-500">
                  info@cayroshop.com
                </a>
              </li>
            </ul>
            <p className="mb-4 text-base md:text-lg">
              We will reimburse you no later than 14 days from the day on which
              we receive the returned Goods. We will use the same means of
              payment as you used for the Order, and you will not incur any fees
              for such reimbursement.
            </p>
            <h3 className="text-xl font-bold mb-4">Conditions for Returns:</h3>
            <p className="mb-4 text-base md:text-lg">
              In order for the Goods to be eligible for a return, please make
              sure that:
            </p>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li>The Goods were purchased in the last 7 days</li>
              <li>The Goods are in the original packaging</li>
            </ul>
            <p className="mb-4 text-base md:text-lg">
              The following Goods cannot be returned:
            </p>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li>
                The supply of Goods made to your specifications or clearly
                personalized.
              </li>
              <li>
                The supply of Goods which according to their nature are not
                suitable to be returned, deteriorate rapidly or where the date
                of expiry is over.
              </li>
              <li>
                The supply of Goods which are not suitable for return due to
                health protection or hygiene reasons and were unsealed after
                delivery.
              </li>
              <li>
                The supply of Goods which are, after delivery, according to
                their nature, inseparably mixed with other items.
              </li>
            </ul>
            <p className="mb-4 text-base md:text-lg">
              We reserve the right to refuse returns of any merchandise that
              does not meet the above return conditions in our sole discretion.
            </p>
            <p className="mb-4 text-base md:text-lg">
              Only regular priced Goods may be refunded. Unfortunately, Goods on
              sale cannot be refunded. This exclusion may not apply to you if it
              is not permitted by applicable law.
            </p>
            <h3 className="text-xl font-bold mb-4">Returning Goods:</h3>
            <p className="mb-4 text-base md:text-lg">
              You are responsible for the cost and risk of returning the Goods
              to us. You should send the Goods to the following address:
            </p>
            <address className="mb-4 text-base md:text-lg">
              Unit no. DCG-0104, DLF Corporate Greens, Sector 74A, Gurugram,
              Haryana - 122001
            </address>
            <p className="mb-4 text-base md:text-lg">
              We cannot be held responsible for Goods damaged or lost in return
              shipment. Therefore, we recommend an insured and trackable mail
              service. We are unable to issue a refund without actual receipt of
              the Goods or proof of received return delivery.
            </p>
            <h3 className="text-xl font-bold mb-4">Gifts:</h3>
            <p className="mb-4 text-base md:text-lg">
              If the Goods were marked as a gift when purchased and then shipped
              directly to you, you'll receive a gift credit for the value of
              your return. Once the returned product is received, a gift
              certificate will be mailed to you.
            </p>
            <p className="mb-4 text-base md:text-lg">
              If the Goods weren't marked as a gift when purchased, or the gift
              giver had the Order shipped to themselves to give it to you later,
              we will send the refund to the gift giver.
            </p>
            <h3 className="text-xl font-bold mb-4">Contact Us:</h3>
            <p className="mb-4 text-base md:text-lg">
              If you have any questions about our Returns and Refunds Policy,
              please contact us:
            </p>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li>
                By email:{" "}
                <a href="mailto:info@cayroshop.com" className="text-blue-500">
                  info@cayroshop.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReturnAndExchange;

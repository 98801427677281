import React from "react";

const TechnicalInfo = (props: any) => {
  let { product } = props;
  const details = [
    { attribute: "ASIN", value: "B09R4SF5SP" },
    {
      attribute: "Customer Reviews",
      value: "4.0 out of 5 stars (3,127 ratings)",
    },
    {
      attribute: "Best Sellers Rank",
      value: "#1,586 in Home & Kitchen (See Top 100)",
    },
    { attribute: "Date First Available", value: "25 January 2022" },
    {
      attribute: "Manufacturer",
      value:
        "Daikin Airconditioning India Pvt. Ltd., 210, 1st Floor, Okhla Industrial Area, Phase 3, Delhi 110020",
    },
    {
      attribute: "Packer",
      value:
        "Daikin Airconditioning India Pvt. Ltd., 210, 1st Floor, Okhla Industrial Area, Phase 3, Delhi 110020",
    },
    {
      attribute: "Importer",
      value:
        "Daikin Airconditioning India Pvt. Ltd., 210, 1st Floor, Okhla Industrial Area, Phase 3, Delhi 110020",
    },
    { attribute: "Item Weight", value: "36 kg" },
    {
      attribute: "Item Dimensions LxWxH",
      value: "80 x 22.9 x 29.8 Centimeters",
    },
    { attribute: "Net Quantity", value: "1 count" },
    { attribute: "Generic Name", value: "Split airconditioner" },
  ];

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-2">Technical Information</h2>
      <div className="bg-white shadow-md rounded my-6">
        <table className="min-w-full leading-normal">
          <tbody>
            {details.map((detail, index) => (
              <tr key={index}>
                <th className="py-2 px-4 bg-gray-100 text-gray-600 font-bold uppercase text-sm border-b border-gray-200">
                  {detail.attribute}
                </th>
                <td className="py-2 px-4 text-gray-900 border-b border-gray-200">
                  {detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TechnicalInfo;

import { Link } from "react-router-dom";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import ProductForSale from "./ProductForSale";
import Brand from "./Brand";
import { GetRequest, PostRequest } from "../../api/Request";
import axios from "axios";
import Loader from "../../common/loader/Loader";
import { useSelector } from "react-redux";
import { allProduct } from "../../slice/CartSlice";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const Home = (props: any) => {
  let { allCategory, subCategories, isLoadingForCategory } = props;
  const containerRef = useRef<any>(null);
  const subCategoryRef = useRef<any>(null);
  const [latestProducts, setLatestProducts] = useState<any>([]);
  const [trendingProducts, setTrendingProducts] = useState<any>([]);
  const [newProducts, setNewProducts] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [recommendedProducts, setRecommendedProducts] = useState<any>([]);
  const [isLoadingForRecommended, setIsLoadingForRecommended] = useState(false);
  const [allBanner, setAllBanner] = useState<any>([]);
  const [isLoadingForBanner, setIsLoadingForBanner] = useState<Boolean>(false);
  const cartProducts = useSelector(allProduct);
  const [isFading, setIsFading] = useState(false);
  let url = process.env.PUBLIC_URL;

  const prevSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? allBanner.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const nextSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isLastSlide = currentIndex === allBanner.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const getAllBanner = async () => {
    setIsLoadingForBanner(() => true);
    try {
      const response = await GetRequest("/banners");
      if (response.status === 200) {
        setAllBanner(() => response.data);
      }
    } catch (error) {
      console.log(error, "error in fetching banners");
    }
    setIsLoadingForBanner(() => false);
  };

  const getAllRecommendedProducts = async () => {
    setIsLoadingForRecommended(() => true);
    try {
      let response = await GetRequest("/recommended-products");
      if (response?.data?.products?.length > 0) {
        setRecommendedProducts(() => {
          return response?.data?.products?.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      } else {
        setRecommendedProducts(() => []);
      }
    } catch (error) {
      console.log(error, "error while fetching recommended products");
    }
    setIsLoadingForRecommended(() => false);
  };

  useEffect(() => {
    getAllBanner();
    getAllLatestProducts();
    getAllTrendingProducts();
    getAllNewProducts();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      getAllRecommendedProducts();
    }
  }, [localStorage.getItem("userToken")]);

  const getAllLatestProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?latest=true");
      if (response.status === 200) {
        setLatestProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {
      console.log(error, "%%%%");
    }
  };

  const getAllTrendingProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?trending=true");
      if (response.status === 200) {
        setTrendingProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {
      console.log(error, "%%%%");
    }
  };

  const getAllNewProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?newLaunches=true");
      if (response.status === 200) {
        setNewProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {
      console.log(error, "%%%%");
    }
  };

  const scrollLeft = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoSlideInterval);
  }, [currentIndex, allBanner.length]);

  return (
    <div className="relative">
      <div className="relative">
        <button
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
          onClick={() => scrollRight(containerRef)}
          className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
          aria-label="Scroll Right"
        >
          <MdOutlineKeyboardArrowRight />
        </button>
        <ul
          ref={containerRef}
          className=" overflow-y-hidden sm:justify-start overflow-x-auto mt-[7.9rem] sm:mt-[7rem] max-w-full flex justify-start pl-4 sm:pl-2 whitespace-nowrap no-scrollbar"
        >
          {allCategory?.length > 0 &&
            allCategory?.map((item: any, index: number) => {
              return (
                <li className={"category sm:!mr-[1rem]"}>
                  <Link
                    to={`product-list?category=${item.category}&category_id=${item._id}&page=1`}
                    className="flex justify-center items-center flex-col"
                  >
                    <img
                      src={item.image.url}
                      className="category-img"
                      alt="category"
                    />
                    <p className="text-[13px] font-[450] sm:max-w-[100px] mt-[0.3rem] sm:text-[11px] text-center">
                      {item?.category}
                    </p>
                  </Link>
                  {/* <Link
                    to={`product-list?category=${item.category}&category_id=${item._id}&page=1`}
                    className="flex justify-center items-center flex-col"
                  >
                    <img 
                      src={item.image.url}
                      className="category-img"
                      alt="category"
                    />
                    <p className="text-[13px] font-[450] sm:max-w-[70px] mt-[0.3rem] sm:text-[11px] text-center break-words">
                      {item?.category}
                    </p>
                    
                  </Link> */}
                </li>
              );
            })}
        </ul>

        <button
          onClick={() => scrollLeft(containerRef)}
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
          className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
          aria-label="Scroll Right"
        >
          <MdOutlineKeyboardArrowLeft />
        </button>
      </div>

      <div className="flex items-center mb-[2.5rem] top-[130px] z-0 sm:top-[83px] xs:top-[5rem] xss:top-[5rem] mt-[0.5rem] sm:my-[0.4rem] absolute">
        {isLoadingForBanner && allBanner.length === 0 ? (
          <div className="flex justify-center items-center h-[200px] sm:h-[83px] sm:w-[83vw] w-[100vw] ">
            <Loader />
          </div>
        ) : (
          <div className="w-[100vw] sm:h-[350px] cursor-pointer xs:h-[300px] xxl:h-[856px] xss:h-[250px] h-[800px] sm:mt-3 mt-6 sm:m-0 m-auto relative overflow-hidden">
            <Link
              to={allBanner[currentIndex]?.banner_link}
              className="cursor-pointer"
            >
              <img
                src={allBanner.length > 0 && allBanner[currentIndex].url}
                alt={allBanner[currentIndex]?.name}
                className={`w-[100vw] h-auto sm:h-[auto] z-[-1] cursor-pointer object-cover duration-500 transition-opacity ${
                  isFading ? "opacity-40" : "opacity-100"
                }`}
              />
            </Link>

            <div className="group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft
                onClick={allBanner.length > 0 ? prevSlide : () => {}}
                size={20}
              />
            </div>

            <div className="group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight
                onClick={allBanner.length > 0 ? nextSlide : () => {}}
                size={20}
              />
            </div>
          </div>
          // <div className="w-[100vw] sm:h-[350px] xs:h-[300px] xxl:h-[856px]  xss:h-[250px] h-[800px] sm:mt-3 mt-6 sm:m-0 m-auto relative">
          //   <img
          //
          //     src={allBanner.length > 0 && allBanner[currentIndex].url}
          //     alt={allBanner[currentIndex]?.name}
          //     className="w-[100vw] h-auto sm:h-[95%] z-[-1] object-cover  duration-500"
          //   />

          //   <div className=" group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          //     <BsChevronCompactLeft
          //       onClick={allBanner.length > 0 ? prevSlide : () => {}}
          //       size={20}
          //     />
          //   </div>

          //   <div className=" group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          //     <BsChevronCompactRight
          //       onClick={allBanner.length > 0 ? nextSlide : () => {}}
          //       size={20}
          //     />
          //   </div>
          // </div>
        )}
      </div>

      <div className="sm:mt-[8rem] mt-[20rem] z-[999]">
        {recommendedProducts?.length > 0 && (
          <ProductForSale
            products={recommendedProducts}
            setProduct={setRecommendedProducts}
            heading={"Recommended Products"}
            relatedProductPage={false}
          />
        )}
        {newProducts.length > 0 && (
          <ProductForSale
            products={newProducts}
            setProduct={setNewProducts}
            heading={"New Launches"}
            relatedProductPage={false}
          />
        )}

        <div className="max-w-[95%] sm:max-w-[95%] my-0 mx-auto z-10 relative ">
          <h2 className="font-semibold text-lg sm:text-sm z-[9999] absolute top-[-41px] sm:static sm:top-0">
            Shop By Products
          </h2>
          <button
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            onClick={() => scrollRight(subCategoryRef)}
            className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowRight />
          </button>
          <ul
            ref={subCategoryRef}
            className="categories-on-home  overflow-y-scroll no-scrollbar"
            style={{
              justifyContent: "flex-start",
              marginBottom: "1rem",
            }}
          >
            {subCategories.length > 0 &&
              subCategories.map((subCat: any, index: number) => {
                return (
                  <li className="category flex justify-center ">
                    <Link
                      to={`product-list?sub-category-id=${subCat._id}&page=1`}
                      className="flex justify-center items-center flex-col"
                    >
                      <img
                        src={subCat.image.url}
                        // className=" w-[120px] h-[120px] object-cover bg-[white]"
                        className="category-img"
                        // style={{
                        //   width: "120px",
                        //   height: "120px",
                        // }}
                        alt="category "
                      />
                      <p className="text-[15px] sm:max-w-[70px] font-[480] mt-[0.3rem] sm:text-[11px] text-center">
                        {subCat?.name}
                      </p>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <button
            onClick={() => scrollLeft(subCategoryRef)}
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
        </div>
        {trendingProducts.length > 0 && (
          <ProductForSale
            products={trendingProducts}
            setProduct={setTrendingProducts}
            heading={"Trending Products"}
            relatedProductPage={false}
          />
        )}

        <Brand />
        {latestProducts.length > 0 && (
          <ProductForSale
            products={latestProducts}
            setProduct={setLatestProducts}
            heading={"Latest Products"}
            relatedProductPage={false}
          />
        )}
      </div>
    </div>
  );
};

export default Home;

{
  /* <div
          className="card"
          style={{
            width: "100%",
            maxWidth: "338px",
            bottom: "14px",
            marginTop: "1.3rem",
            marginRight: "2rem",
            padding: "2px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 4px",
            borderRadius: "6px",
          }}
        >
          <img 
            src="https://img.freepik.com/premium-vector/pos-payment-terminal-approved-payment-vector-stock-illustration_100456-2343.jpg?w=740"
            alt="Side Image"
            style={{ width: "100%", height: "auto", borderRadius: "4px" }}
          />
        </div> */
}

import React from "react";

const Policies = (props: any) => {
  let { product } = props;
  return (
    <div className="p-4">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-bold mb-8">Store Policies</h1>

        <div id="privacy-policy" className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
          <p className="mb-4">
            Our privacy policy outlines how we collect, use, store, and protect
            your data.
          </p>

          <h3 className="text-xl font-medium mb-2">Information Collection</h3>
          <p className="mb-4">
            We collect personal details, payment information, and other relevant
            data during your interaction with our store.
          </p>

          <h3 className="text-xl font-medium mb-2">Usage</h3>
          <p className="mb-4">
            Your information is used for order processing, marketing, and
            improving our services.
          </p>

          <h3 className="text-xl font-medium mb-2">Protection</h3>
          <p className="mb-4">
            We employ encryption, secure servers, and other measures to protect
            your data from unauthorized access.
          </p>

          <h3 className="text-xl font-medium mb-2">Third Parties</h3>
          <p className="mb-4">
            We may share your data with third parties for order fulfillment and
            marketing purposes.
          </p>
        </div>

        <div id="terms-conditions" className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
          <p className="mb-4">
            These terms govern the use of our website and the purchase of
            products from our store.
          </p>

          <h3 className="text-xl font-medium mb-2">Introduction</h3>
          <p className="mb-4">
            By using our website, you accept our terms and conditions. Please
            read them carefully.
          </p>

          <h3 className="text-xl font-medium mb-2">Products and Services</h3>
          <p className="mb-4">
            We offer a variety of products and services. Availability and
            descriptions are subject to change.
          </p>

          <h3 className="text-xl font-medium mb-2">Pricing and Payment</h3>
          <p className="mb-4">
            All prices are listed in [Currency]. We accept [Payment Methods].
            Taxes may apply based on your location.
          </p>

          <h3 className="text-xl font-medium mb-2">Order Process</h3>
          <p className="mb-4">
            To place an order, add items to your cart and proceed to checkout.
            Follow the on-screen instructions to complete your purchase.
          </p>

          <h3 className="text-xl font-medium mb-2">User Obligations</h3>
          <p className="mb-4">
            Users must provide accurate information and refrain from any
            fraudulent activities on our site.
          </p>

          <h3 className="text-xl font-medium mb-2">Liability Limitations</h3>
          <p className="mb-4">
            We are not liable for any indirect, incidental, or consequential
            damages arising from the use of our services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policies;

import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "../slice/UserSlice";
import CartSlice from "../slice/CartSlice";
import { wishlist } from "../slice/WishlistSlice";
import ProductSlice from "../slice/ProductSlice";
// import counterReducer from '../slices/counterSlice';

const store = configureStore({
  reducer: {
    user: UserSlice,
    cart: CartSlice,
    wishList: wishlist,
    product: ProductSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import React, { useState } from "react";
import { Link } from "react-router-dom";
interface RatingBarProps {
  stars: number;
  percentage: number;
}

const RatingBar: React.FC<RatingBarProps> = ({ stars, percentage }) => (
  <tr className="flex items-center">
    <td className="whitespace-nowrap">
      <Link className="text-blue-600" to="/">
        {stars} star
      </Link>
    </td>
    <td className="flex-1 mx-2">
      <Link to="/">
        <div className="h-3 bg-gray-300 rounded-full overflow-hidden">
          <div
            className="bg-green-500 h-full"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </Link>
    </td>
    <td className="whitespace-nowrap text-right">
      <Link className="text-blue-600" to="/">
        {percentage}%
      </Link>
    </td>
  </tr>
);

export const Reviews = (props: any) => {
  let { product } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="p-4">
      <div className="p-4">
        <h2 className="text-xl font-bold">Customer Ratings</h2>
        {/* <div className="flex items-center my-4">
          <i className="a-icon a-icon-star-medium a-star-medium-4"></i>
          <span className="ml-2 text-lg">4 out of 5</span>
        </div>
        <div className="text-gray-600">27 global ratings</div>
        <table className="w-full mt-4">
          <tbody>
            <RatingBar stars={5} percentage={54} />
            <RatingBar stars={4} percentage={21} />
            <RatingBar stars={3} percentage={8} />
            <RatingBar stars={2} percentage={3} />
            <RatingBar stars={1} percentage={14} />
          </tbody>
        </table> */}
        {/* <div className="mt-4">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center text-blue-600"
          >
            <i
              className={`a-icon ${
                isExpanded ? "a-icon-collapse" : "a-icon-expand"
              }`}
            ></i>
            <span className="ml-2">How are ratings calculated?</span>
          </button>
          {isExpanded && (
            <div className="mt-2 text-gray-700">
              To calculate the overall star rating and percentage breakdown by
              star, we don’t use a simple average. Instead, our system considers
              things like how recent a review is and if the reviewer bought the
              item on Amazon. It also analyses reviews to verify
              trustworthiness.
            </div>
          )}
        </div> */}
      </div>
      {/* //////////////////////////////// */}
      <hr></hr>
      <hr></hr>
      <div className="p-4">
        <h3 className="text-lg font-bold mb-2">Review this product</h3>
        <div className="text-gray-700 mb-4">
          Share your thoughts with other customers
        </div>
        <div className="flex">
          <p className="bg-gray-200 hover:bg-gray-100 text-black py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all">
            Write a product review
          </p>
        </div>
      </div>
      <br></br>
      <hr></hr>
      <hr></hr>
      <br></br>
      <br></br>
      {/* /////////////////////////////// */}
      <h2 className="text-xl font-bold mb-2 ml-4 ">Customer Says</h2>
      <div id="product-summary" className="a-section a-spacing-medium">
        <div className="a-section a-spacing-small a-spacing-top-medium"></div>
        <p className="a-spacing-small ml-8">
          <span>
            Customers like the noise level, efficiency, and quality of the air
            conditioner. For example, they mention it's a super power saver, and
            it'll cool a room in just 10 minutes. That said, opinions are mixed
            on the cooling, value, performance, and ease of installation.
          </span>
        </p>
        <p className="a-size-small a-color-secondary ml-8">
          AI-generated from the text of customer reviews
        </p>
      </div>

      {/* //////////////////////////////////////// */}
      <br></br>
      <br></br>
      <div className="flex ml-8">
        <Link
          to="/"
          className="mr-2 flex text-xs bg-gray-200 hover:bg-gray-100 text-black py-2 px-2 w-20 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all"
        >
          <svg
            fill="currentColor"
            className="w-4 h-4 flex-row mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
          </svg>
          Helpful
        </Link>
        <Link
          to="/"
          className="ml-2 flex text-xs bg-gray-200 hover:bg-gray-100 text-black py-2 px-2 w-20 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all"
        >
          <svg
            fill="currentColor"
            className="w-4 h-4 flex-row mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.38 1.38 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51q.205.03.443.051c.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.9 1.9 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2 2 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.2 3.2 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.8 4.8 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591" />
          </svg>
          Report
        </Link>
      </div>
    </div>
  );
};

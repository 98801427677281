import React from "react";
import { Link } from "react-router-dom";

const Inquiries = (props: any) => {
  let { product } = props;
  return (
    <div className="p-4">
      <h3 className="text-lg font-bold mb-2">Ask Questions?</h3>
      <div className="text-gray-700 mb-4">
        Ask your question directly from the Manufacturer
      </div>
      <div className="flex">
        <Link
          to="/"
          className="bg-gray-200 hover:bg-gray-100 text-black py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-all"
        >
          Write Your Question here.
        </Link>
      </div>
      <br></br>
      <hr></hr>
      <hr></hr>
      <hr></hr>
      <br></br>
      <h3 className="text-md font-semibold mb-2">What is in the box?</h3>
      <div className="text-gray-700 mb-4">
        1 Indoor Unit, 1 Outdoor Unit, 3 m Length Inter Connecting Copper Pipe,
        1 Remote, 1 Manual.
      </div>

      <hr></hr>
      <br></br>
      <h3 className="text-md font-semibold mb-2">
        Warranty of how many Years?
      </h3>
      <div className="text-gray-700 mb-4">1 Year</div>

      <hr></hr>
      <br></br>
      <h3 className="text-md font-semibold mb-2">Replacement?</h3>
      <div className="text-gray-700 mb-4">Replacement within 10 Days</div>
    </div>
  );
};

export default Inquiries;

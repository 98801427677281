import React, { useState } from "react";

const AdBan: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closeBanner = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto relative">
        <button
          className="absolute top-2 right-2 text-gray-500 text-white font-bold  p-2 "
          style={{
            background: "linear-gradient(90deg, #1DA1F2, #FF69B4)",
          }}
          onClick={closeBanner}
        >
          &times;
        </button>
        <div className="flex flex-col items-center">
          <img
            src="https://img.freepik.com/premium-photo/big-sale-discounts-products_23-2150336658.jpg?w=1060"
            alt="Watch"
            className="w-100 h-80 object-cover mb-4"
          />
          <h2
            className="text-2xl font-bold mb-2 bg-clip-text text-transparent"
            style={{
              backgroundImage: "linear-gradient(90deg, #1DA1F2, #FF69B4)",
            }}
          >
            Exclusive Watch
          </h2>
          <p className="text-gray-600 mb-4">
            Discover our latest Ear Phone and Head Phones Collection with
            exclusive designs and features.
          </p>
          <button
            className="text-white py-2 px-4 rounded hover:bg-blue-700"
            style={{
              background: "linear-gradient(90deg, #1DA1F2, #FF69B4)",
            }}
          >
            Shop Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdBan;

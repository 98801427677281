import React from "react";

const About: React.FC = () => {
  return (
    <div className="container mx-auto px-4 mt-[5rem] sm:mt-[8rem]">
      <main role="main" className="flex flex-col items-center mx-auto">
        <div className="w-full  blog-main">
          <div className="mb-4 mt-6">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Welcome to CayroShop
            </h2>
            <p className="mb-4 text-base md:text-lg">
              Your premier destination for all things electronic and
              appliance-related. At CayroShop, we believe in providing our
              customers with the latest and greatest in technology, delivered
              with unparalleled customer service and satisfaction.
            </p>
            <h3 className="text-xl font-bold mb-4">Our Mission:</h3>
            <p className="mb-4 text-base md:text-lg">
              At CayroShop, our mission is simple - to revolutionize your
              shopping experience by offering an extensive selection of
              top-quality products, competitive prices, and exceptional customer
              service. We strive to be your one-stop destination for all your
              electronic and appliance needs, making shopping convenient,
              enjoyable, and hassle-free.
            </p>
            <h3 className="text-xl font-bold mb-4">Our Products:</h3>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li className="mb-2">
                <strong>Mobiles & Tablets:</strong> Stay connected and
                productive with the latest smartphones and tablets from leading
                brands.
              </li>
              <li className="mb-2">
                <strong>Televisions:</strong> Immerse yourself in the ultimate
                viewing experience with our wide selection of high-definition
                televisions, ranging from LED to OLED and beyond.
              </li>
              <li className="mb-2">
                <strong>Audio:</strong> Elevate your audio experience with our
                collection of speakers, headphones, soundbars, and more,
                designed to deliver crystal-clear sound quality.
              </li>
              <li className="mb-2">
                <strong>Home Appliances:</strong> Make life easier with our
                range of home appliances, including refrigerators, washing
                machines, air conditioners, and more, from trusted brands known
                for their reliability and performance.
              </li>
              <li className="mb-2">
                <strong>Computers:</strong> Whether you're a professional or a
                casual user, we have the perfect computing solutions for you,
                including laptops, desktops, and accessories to enhance your
                productivity.
              </li>
              <li className="mb-2">
                <strong>Accessories:</strong> Find the perfect accessories to
                complement your devices, from protective cases and chargers to
                cables and adapters.
              </li>
              <li className="mb-2">
                <strong>Kitchen Appliances:</strong> Transform your kitchen into
                a culinary haven with our selection of kitchen appliances,
                including blenders, coffee makers, toasters, and more, designed
                to simplify meal preparation and cooking.
              </li>
              <li className="mb-2">
                <strong>Personal Care:</strong> Take care of yourself with our
                range of personal care products, including grooming essentials,
                beauty tools, and health monitors, to help you look and feel
                your best every day.
              </li>
            </ul>
            <h3 className="text-xl font-bold mb-4">Why Choose CayroShop?</h3>
            <ul className="list-disc list-inside mb-4 text-base md:text-lg">
              <li className="mb-2">
                Quality Assurance: We source our products from reputable
                manufacturers to ensure superior quality and reliability.
              </li>
              <li className="mb-2">
                Competitive Pricing: Enjoy competitive prices on all our
                products, allowing you to get more value for your money.
              </li>
              <li className="mb-2">
                Fast Shipping: We offer fast and reliable shipping options to
                ensure your order reaches you in a timely manner.
              </li>
              <li className="mb-2">
                Dedicated Customer Support: Our friendly and knowledgeable
                customer support team is here to assist you with any questions
                or concerns you may have.
              </li>
            </ul>
            <h3 className="text-xl font-bold mb-4">Shop with Confidence:</h3>
            <p className="mb-4 text-base md:text-lg">
              Shop with confidence at CayroShop and experience the convenience
              of online shopping combined with the reliability of a trusted
              retailer. Whether you're upgrading your gadgets, enhancing your
              entertainment setup, or outfitting your home with the latest
              appliances, CayroShop has you covered. Explore our website today
              and discover a world of possibilities at your fingertips.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;

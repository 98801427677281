import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputNumber from "../../common/component/InputNumber";
import OTP from "./otp";
import InputText from "../../common/component/InputText";
import Loader from "../../common/component/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading, IsLoggedIn, login } from "../../slice/UserSlice";
import { AppDispatch } from "../../store/store";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { PostRequest } from "../../api/Request";

interface Error {
  password: string;
  phoneNumber: string;
}
interface userInfo {
  password: string;
  phoneNumber: number | undefined;
  error: Error;
}

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [agreed, setAgreed] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const isLoading = useSelector(IsLoading);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({
    phoneNumber: "", // Initialize phoneNumber as a string
    password: "",
    error: {
      phoneNumber: "",
      password: "",
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    if (userIsLoggedIn) {
      if (redirect === "cart") {
        navigate("/cart");
      } else {
        navigate("/");
      }
    }
  }, [userIsLoggedIn, location.search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let errorMessage = "";
    switch (name) {
      case "password":
        if (value.trim() === "") {
          errorMessage = "Password is required.";
        } else {
          errorMessage = "";
        }
        break;
      case "phoneNumber":
        const phoneNumberPattern = /^[6789]\d{9}$/;
        if (value.trim() === "") {
          errorMessage = "Phone number is required.";
        } else if (value.length !== 10) {
          errorMessage = "Phone number must be 10 digits.";
        } else if (!phoneNumberPattern.test(value)) {
          errorMessage = "Please enter a valid phone number.";
        } else {
          errorMessage = "";
        }
        break;
      default:
        break;
    }

    setUserInfo((prevState: any) => ({
      ...prevState,
      [name]: value,
      error: {
        ...prevState.error,
        [name]: errorMessage,
      },
    }));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const handleLogin = async () => {
    let updatedUserInfo = { ...userInfo };
    if (!userInfo.password) {
      updatedUserInfo.error.password = "Password is required.";
    }
    if (!userInfo.phoneNumber) {
      updatedUserInfo.error.phoneNumber = "Phone number is required.";
    }
    setUserInfo(updatedUserInfo);

    if (
      userInfo.password &&
      userInfo.phoneNumber &&
      !userInfo.error.phoneNumber &&
      !userInfo.error.password
    ) {
      await dispatch(
        login({
          number: String(userInfo.phoneNumber),
          password: userInfo.password,
        })
      );
      if (userIsLoggedIn) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="flex items-center justify-center h-[80vh] mt-[5rem]">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm text-center sm:w-[90%] transition-transform transform hover:scale-105">
          <h1 className="text-2xl  text-[#3f0e9e]">Login cayroshop</h1>
          <label className="block mt-4 text-left text-gray-700 ">
            Enter Mobile Number:
            <InputNumber
              name="phoneNumber"
              placeholder="Phone number"
              value={userInfo?.phoneNumber}
              onHandleChange={handleChange}
              minLength={10}
            />
            <p className="text-red-500 text-xs italic">
              {userInfo.error.phoneNumber}
            </p>
          </label>
          <label className="block mt-4 text-left text-gray-700 mb-2">
            Password:
            <div className="relative">
              <InputText
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="enter your password"
                value={userInfo.password}
                onHandleChange={handleChange}
                disabled={false}
              />
              <span
                className="absolute top-[50%] right-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <p className="text-red-500 text-xs italic">
              {userInfo.error.password}
            </p>
          </label>
          <div className="mt-1 mb-4 text-end">
            <Link to="/forget-password" className="text-blue-600 text-sm">
              Forget Password?
            </Link>
          </div>

          <button
            onClick={handleLogin}
            className="block w-full cursor-pointer relative text-center px-4 py-2 text-white bg-[#3f0e9e] rounded hover:bg-[#3f0e9e] focus:bg-[#3f0e9e] focus:outline-none mb-4"
          >
            Login
            {isLoading ? (
              <div className="absolute top-[-18px] right-[39%]">
                <Loader />
              </div>
            ) : (
              ""
            )}
          </button>
          <Link
            to="/register"
            className="text-blue-600 text-center text-sm mt-[0.5rem]"
          >
            Click here to register, if you are not an existing user.
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState } from "react";

interface Review {
  rating: number;
  reviewText: string;
  images: File[];
  videos: File[];
}

const Review: React.FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [reviewText, setReviewText] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);

  const handleStarClick = (value: number) => {
    setRating(value === rating ? 0 : value); // Toggle rating
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedImages = Array.from(event.target.files);
      setImages(selectedImages);
    }
  };

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedVideos = Array.from(event.target.files);
      setVideos(selectedVideos);
    }
  };

  const removeImage = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const removeVideo = (index: number) => {
    setVideos(videos.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (!rating || !reviewText) {
      alert("Please select a rating and provide a review before submitting.");
      return;
    }

    const newReview: Review = { rating, reviewText, images, videos };
    setReviews([...reviews, newReview]);

    // Reset after submitting
    setRating(0);
    setReviewText("");
    setImages([]);
    setVideos([]);
  };

  const getStarColorClass = (value: number): string => {
    switch (value) {
      case 1:
        return "text-red-600";
      case 2:
        return "text-orange-600";
      case 3:
        return "text-yellow-300";
      case 4:
        return "text-yellow-500";
      case 5:
        return "text-green-600";
      default:
        return "";
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url('https://i.pinimg.com/564x/20/66/23/206623ba921da6ba959f67e039368d05.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-transparent p-5 rounded-lg w-11/12 text-center">
        <h1 className="text-2xl mb-4 font-bold text-white">Product Review</h1>

        <div className="flex justify-between mb-4">
          {/* Rating Column */}
          <div className="flex flex-col items-center justify-center w-1/4">
            <div className="text-xl mb-2 text-white">
              <span className="text-white" id="rating">
                {rating}
              </span>
              /5
            </div>
            <div className="flex justify-center mb-4" id="stars">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star cursor-pointer mx-1 ${getStarColorClass(
                    value
                  )} ${rating >= value ? "bi-star-fill" : "bi-star"}`}
                  onClick={() => handleStarClick(value)}
                >
                  {rating >= value ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-star-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                    </svg>
                  )}
                </span>
              ))}
            </div>
          </div>

          {/* Image and Video Column */}
          <div className="flex flex-col items-center justify-center w-1/2">
            <div className="flex items-center justify-center mb-4 border-4 p-6">
              <div className="flex items-center justify-center p-2 m-1 border-r-4">
                <label htmlFor="image-upload" className="cursor-pointer mr-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    fill="#fff"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    multiple
                    className="hidden"
                    onChange={handleImageUpload}
                  />
                </label>
              </div>
              <div className="flex items-center justify-center p-2">
                <label
                  htmlFor="video-upload"
                  className="cursor-pointer ml-10 border-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="#fff"
                    className="bi bi-film"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm4 0v6h8V1zm8 8H4v6h8zM1 1v2h2V1zm2 3H1v2h2zM1 7v2h2V7zm2 3H1v2h2zm-2 3v2h2v-2zM15 1h-2v2h2zm-2 3v2h2V4zm2 3h-2v2h2zm-2 3v2h2v-2zm2 3h-2v2h2z" />
                  </svg>
                  <input
                    id="video-upload"
                    type="file"
                    accept="video/*"
                    multiple
                    className="hidden"
                    onChange={handleVideoUpload}
                  />
                </label>
              </div>
            </div>

            {/* Preview of Images */}
            <div className="flex flex-wrap mb-4">
              {images.map((image, index) => (
                <div key={index} className="relative m-2">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                    className="w-24 h-24 object-cover rounded"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>

            {/* Preview of Videos */}
            <div className="flex flex-wrap mb-4">
              {videos.map((video, index) => (
                <div key={index} className="relative m-2">
                  <video
                    src={URL.createObjectURL(video)}
                    className="w-24 h-24 object-cover rounded"
                    controls
                  />
                  <button
                    onClick={() => removeVideo(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Review Text Column */}
          <div className="flex flex-col items-center justify-center w-1/4">
            <textarea
              className="resize-none w-full p-2 border rounded h-40"
              placeholder="Write your review..."
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
            />
          </div>
        </div>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Submit Review
        </button>

        {/* Display reviews */}
        <div className="mt-4">
          {reviews.map((review, index) => (
            <div key={index} className="bg-white p-4 mb-4 rounded-lg shadow-lg">
              <div className="flex items-center mb-2">
                <span className="text-xl font-bold mr-2">{review.rating}</span>
                {[...Array(review.rating)].map((_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-star-fill text-yellow-500"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                ))}
              </div>
              <p>{review.reviewText}</p>
              <div className="flex flex-wrap mt-2">
                {review.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={URL.createObjectURL(image)}
                    alt={`Review ${imgIndex}`}
                    className="w-16 h-16 object-cover m-1"
                  />
                ))}
              </div>
              <div className="flex flex-wrap mt-2">
                {review.videos.map((video, vidIndex) => (
                  <video
                    key={vidIndex}
                    src={URL.createObjectURL(video)}
                    className="w-16 h-16 object-cover m-1"
                    controls
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Review;

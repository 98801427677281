import React, { useEffect, useState } from "react";
import AccountSidebar from "../SideBar";
import { GetRequest } from "../../../api/Request";
import Loader from "../../../common/loader/Loader";
import { showErrorToast } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { UserInfo } from "../../../slice/UserSlice";
import { useLocation, useSearchParams } from "react-router-dom";
import OrderSuccess from "../../banners/OrderSuccess";
import { clearCart } from "../../../slice/CartSlice";
import { AppDispatch } from "../../../store/store";
import PaginationForOrder from "../../../pagination/PaginationForOrder";

const OrderList: React.FC = () => {
  const [allOrder, setAllOrder] = useState<any>([]);
  const [isLoadingForOrder, setIsLoadingForOrder] = useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const [pageNumber, setPageNumber] = useState(query.get("page"));
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();

  useEffect(() => {
    getAllOrder();
    setPageNumber(() => query.get("page"));
    window.scrollTo(0, 0);
  }, [query.get("page")]);

  const getAllOrder = async () => {
    setIsLoadingForOrder(true);
    try {
      let response = await GetRequest(`getAllOrder?page=${query.get("page")}`);
      if (response.status === 200) {
        setAllOrder(() => response.data.orders);
        setTotalPages(() => response.data.totalPages);
        setTotalItems(() => response.data.totalOrders);
      }
    } catch (error) {
      showErrorToast("Error in fetching orders");
    } finally {
      setIsLoadingForOrder(false);
    }
  };

  useEffect(() => {
    if (
      searchParams.get("order_id") &&
      searchParams.get("message") &&
      searchParams.get("amount")
    ) {
      dispatch(clearCart());
    }
  }, []);

  const getOrderStatusWidth = (status: any) => {
    switch (status) {
      case "Accepted":
        return "33%"; // For example, if accepted is 33%
      case "Shipped":
        return "66%"; // If shipped is 66%
      case "Delivered":
        return "100%"; // If delivered is 100%
      default:
        return "0%"; // Default width if no status is set
    }
  };

  return (
    <div className="flex mt-[8rem] items-start mx-auto my-0  justify-evenly flex-wrap  mb-[1rem]">
      <div className="basis-[20%] sm:hidden mt-[1rem] border-r-[1px] border-r-[#ddd]">
        <AccountSidebar />
      </div>
      {searchParams.get("order_id") &&
      searchParams.get("message") &&
      searchParams.get("amount") ? (
        <OrderSuccess
          orderId={searchParams.get("order_id")}
          message={searchParams.get("message")}
          amount={searchParams.get("amount")}
        />
      ) : (
        ""
      )}
      <div className="basis-[80%] sm:basis-[100%]">
        <div className="ml-4 sm:ml-4">
          <h1 className="font-medium  text-2xl sm:text-xl sm:mb-3">
            My Orders
          </h1>
        </div>
        <div className=" p-6 flex flex-col w-[90%] sm:w-[100%] ml-[1rem] flex-start:start rounded-xl sm:p-[0.6rem] sm:ml-[0rem]">
          <div className="flex md:flex-col flex-row lg:space-x-6">
            <div className="flex-grow">
              {allOrder?.length > 0 ? (
                allOrder?.map((order: any, index: number) => (
                  <div className="border-[0.5px] border-[#ddd] mb-[2rem] rounded-xl p-6 sm:p-3">
                    <div className="flex flex-col">
                      <p className="mb-[0.25rem]">
                        <span className="text-gray-600">Order ID:</span> #
                        {order?._id}
                      </p>
                      <p className="mb-[0.25rem]">
                        <span className="text-gray-600">Order Status :</span>{" "}
                        {order?.orderStatus}
                      </p>
                      <p className="mb-[0.25rem]">
                        <span className="text-gray-600">Payment Status :</span>{" "}
                        {order?.paymentStatus}
                      </p>
                    </div>

                    <div
                      key={index}
                      className="flex flex-col  justify-between mb-6"
                    >
                      {order.productDetailsInfo.length > 0 &&
                        order.productDetailsInfo.map((item: any) => {
                          return (
                            <div className="flex space-x-4 mb-[0.9rem]">
                              <img
                                className="w-24 h-24  object-contain rounded-md"
                                src={
                                  item?.images?.length > 0 && item.images[0].url
                                }
                                alt="Product Image"
                              />
                              <div>
                                <h2 className="text-[16px] font-[500]">
                                  {item.productName}
                                </h2>

                                <p className="text-orange-600 text-[15px]">
                                  ₹{item.salePrice}
                                </p>
                                <h2 className="text-gray-600 text-sm">
                                  Quantity - {item.quantity}
                                </h2>
                                <p className="mt-2 text-gray-500 text-sm">
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="p-[0.8rem] sm:p-0">
                      <div className="mt-6 lg:mt-0 md:w-fit bg-gray-50 p-4 sm:p-[0.4rem] rounded-lg lg:flex-none">
                        <div>
                          <h4 className="text-sm sm:text-xs font-medium text-gray-600">
                            Delivery address
                          </h4>

                          {
                            <div>
                              <span className=" text-[15px]">
                                {order?.shippingAddress?.name + ", "}
                              </span>
                              <span className=" text-[15px]">
                                {order?.shippingAddress?.mobileNumber + ", "},
                              </span>
                              <span>
                                {order?.shippingAddress?.address1 + ", "},{" "}
                              </span>
                              <span>
                                {order?.shippingAddress?.address2 + ", "}{" "}
                              </span>
                              <span>
                                {order?.shippingAddress?.city + ", "}{" "}
                              </span>
                              <span>
                                {order?.shippingAddress?.state + ", "}{" "}
                              </span>
                              <span>
                                {order?.shippingAddress?.postalCode + ", "}{" "}
                              </span>
                              <span>{order?.shippingAddress?.country} </span>
                            </div>
                          }
                          {/* <p className="text-gray-700">Floyd Miles</p>
                        <p className="text-gray-700">7363 Cynthia Pass</p>
                        <p className="text-gray-700">Toronto, ON N3Y 4H8</p> */}
                        </div>
                        {/* <div className="mt-4">
                          <h4 className="text-sm font-medium text-gray-600">
                            Shipping updates
                          </h4>
                        
                          <a
                            href="#"
                            className="text-indigo-600 text-sm mt-1 inline-block"
                          >
                            Edit
                          </a>
                        </div> */}
                      </div>
                      <div>
                        <div className="relative pt-2">
                          {/* Calculate the width based on order status */}
                          <div className="overflow-hidden h-2 text-xs flex bg-gray-200 rounded">
                            <div
                              style={{
                                width: getOrderStatusWidth(order.orderStatus),
                              }} // Call the function to get the width
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-600"
                            ></div>
                          </div>
                          <div className="flex xs:flex-col flex-row justify-between text-sm text-gray-500 mt-2 text-center md:text-left">
                            <span
                              className={
                                order.orderStatus === "Accepted"
                                  ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                  : "mb-2 md:mb-0"
                              }
                            >
                              Accepted
                            </span>
                            <span
                              className={
                                order.orderStatus === "Shipped"
                                  ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                  : "mb-2 md:mb-0"
                              }
                            >
                              Shipped
                            </span>
                            <span
                              className={
                                order.orderStatus === "Delivered"
                                  ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                  : "mb-2 md:mb-0"
                              }
                            >
                              Delivered
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*    <span className="text-indigo-600 font-medium mb-2 md:mb-0">
                              Order placed
                            </span> */}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-red-400 text-center">No Order Found</p>
              )}
              {allOrder.length > 0 && (
                <PaginationForOrder
                  totalPages={totalPages}
                  totalItems={totalItems}
                  pageNumber={pageNumber}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
